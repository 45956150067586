import { useAuthState } from '../../../../state/useAuthState';
import Icon from '@ant-design/icons';
import { Checked, ExclamationSquare, NoChecked } from '../../../../assets/img/icons/icons';
import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { StatusTag } from '../../../../components/ui/tags/status-tag';
import { ErrorBoundary } from '../../../../components/ErrorBoundary';
import { useNavigate } from 'react-router-dom';
import { useCookiesCustom } from '../../../../hooks/useCookieCustom';

type TStatusTagStatus = {
  status: string
}

const StatusTagStatus = ({ status }: TStatusTagStatus) => {
  const {cookie, removeCookie} = useCookiesCustom();
  const logout = useAuthState((state) => state.logout);
  const navigate = useNavigate();
  const [userStatus, setUserStatus] = useState('');
  const [isEmailVerified, setIsEmailVerified] = useState<boolean>(cookie['email_verified'] !== 'true');

  useEffect(() => {
    console.log('cookie[\'email_verified\'] !== \'true\'', cookie['email_verified'] !== 'true');
    setIsEmailVerified(cookie['email_verified'] !== 'true');
  }, [cookie.email_verified]);

  useEffect(() => {
    console.debug('user status changed to:', status);
    setUserStatus(status);
  }, [status]);

  return (
    <ErrorBoundary logout={logout} removeCookie={removeCookie} navigate={navigate}>
      {(isEmailVerified && (
        <>
          <StatusTag preText={t('titles.profileStatus').toString()}
                     isConnected={false}
                     isMoratorium={userStatus === 'moratorium'}
                     noIcon={true}>
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {/*@ts-ignore*/}
              {isEmailVerified ? (
                <>
                          <span
                            style={{
                              padding: '0 10px',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Icon
                              component={Checked}
                              style={{ fontSize: '16px' }}
                            />
                          </span>
                  {t('profileStatuses.activated')}
                </>
              ) : (
                <>
                          <span
                            style={{
                              padding: '0 10px',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Icon
                              component={NoChecked}
                              style={{ fontSize: '16px' }}
                            />
                          </span>
                  {t('profileStatuses.notActivated')}
                </>
              )}
                    </span>
          </StatusTag>
        </>
      )) || (userStatus === 'moratorium' && (
        <>
          <StatusTag
            preText={t('titles.profileStatus').toString()}
            isConnected={false}
            noIcon={true}
            isMoratorium={true}
          >
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <>
                        <span
                          style={{
                            padding: '0 10px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Icon
                            component={ExclamationSquare}
                            style={{ fontSize: '16px' }}
                          />
                        </span>
                        {t('statuses.moratorium')}
                      </>
                    </span>
          </StatusTag>
        </>
      ))
      }
    </ErrorBoundary>
  );

};

export default StatusTagStatus;
