import { useEffect, useState } from 'react';
import { useResize } from '../../hooks/useResize';
import {
  PrivacyPolicyPageBlockStyled,
  PrivacyPolicyPageStyled,
  PrivacyPolicyPageTitleStyled,
  TitleH1Styled,
  WrapperContentStyled,
} from './styles';
import { useTranslation } from 'react-i18next';

const TermsOfUse = () => {
  // const { t } = useTranslation();
  const { isScreen1199, isScreen991, isScreen767, isScreen575 } = useResize();
  const [language, setLanguage] = useState<'en' | 'ru'>('en');
  const { i18n } = useTranslation();

  useEffect(() => {
    if (window.location.pathname.indexOf('/ru') === -1) {
      setLanguage('en');
    } else {
      setLanguage('ru');
    }
  }, []);

  useEffect(() => {
    const checkReflection = () => {
      const ht = document.querySelector('html');
      if (ht && i18n.language === 'ar') {
        ht.className = '';
      }
    };
    checkReflection();
  }, [i18n.language]);

  return (
    <WrapperContentStyled
      $isScreen1199={isScreen1199}
      $isScreen991={isScreen991}
      $isScreen767={isScreen767}
      $isScreen575={isScreen575}
    >
      <PrivacyPolicyPageStyled
        $isScreen991={isScreen991}
        $isScreen767={isScreen767}
        $isScreen575={isScreen575}
      >
        <TitleH1Styled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          {language === 'ru'
            ? 'Правила пользования'
            : 'Terms of use'}
          {/* {t("Политика в отношении обработки персональных данных")} */}
        </TitleH1Styled>

        <PrivacyPolicyPageTitleStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          How Edenex use cookies and other similar technology on this website.
        </PrivacyPolicyPageTitleStyled>
        <PrivacyPolicyPageBlockStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          This policy is effective as of May 24, 2022. Please note that this privacy statement will be updated from time
          to time.
          We can place cookies and other similar technology on your device, including mobile device, in accordance with
          your preferences set on our cookie setting center. Depending on your settings in our cookie consent manager on
          your mobile device, the following information may be collected through cookies or similar technology: your
          unique device identifier, mobile device IP address, information about your device’s operating system, mobile
          carrier and your location information (to the extent permissible under applicable law).
          Every time you visit Edenex website, you will be prompted to accept or reject cookies, you can also tailor
          your selection.
        </PrivacyPolicyPageBlockStyled>
        <PrivacyPolicyPageTitleStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          What are cookies?
        </PrivacyPolicyPageTitleStyled>
        <PrivacyPolicyPageBlockStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          Cookies are small text files that are placed into user’s device when you visit a website, downloaded to your
          computer or mobile device when you visit a site and allow a site to recognize your device. Cookies stores
          information about the user’s visit, which may include content viewed, language preference, time and duration
          of each visit and advertisement accessed. Cookies managed by Edenex only are called “first party cookies”
          whereas cookies from third parties are called “third party cookies”.
        </PrivacyPolicyPageBlockStyled>
        <PrivacyPolicyPageTitleStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          Why do we use cookies and similar technologies?
        </PrivacyPolicyPageTitleStyled>
        <PrivacyPolicyPageBlockStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          Cookies are a useful mechanism that do a lot of different jobs, such as letting you navigate between pages
          efficiently, remembering your preferences and generally improving the user experience. They can help to ensure
          that the advertisements you see online are more relevant to you and your interests and enable us to identify
          your preferences. There are different set of cookies, and we will explain that later in this document.
        </PrivacyPolicyPageBlockStyled>
        <PrivacyPolicyPageTitleStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          Does Edenex use cookies for marketing and analytics?
        </PrivacyPolicyPageTitleStyled>
        <PrivacyPolicyPageBlockStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          Yes, we may use information collected from our cookies to identify user behaviour and to serve content and
          offers based on your profile, and for the other purposes described below, to the extent legally permissible in
          certain jurisdictions.
          In other cases, we can associate cookie information (including information from cookies placed via our
          advertisements on third party sites) with an identifiable individual.
        </PrivacyPolicyPageBlockStyled>
        <PrivacyPolicyPageBlockStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          Do you use any cookies from third party companies?
        </PrivacyPolicyPageBlockStyled>
        <PrivacyPolicyPageBlockStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          Some cookies, web beacons and other tracking and storage technologies that we use are from third party
          companies (third party cookies) to provide us with web analytics and intelligence about our sites which may
          also be used to provide measurement services and target ads.
        </PrivacyPolicyPageBlockStyled>
        <PrivacyPolicyPageTitleStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          What if I don’t want cookies or similar tracking technologies?
        </PrivacyPolicyPageTitleStyled>
        <PrivacyPolicyPageBlockStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          You can adjust your preferences about cookies through our cookie setting center. If you want to remove
          existing cookies from your device, you can do this using your browser options.
          If you want to block future cookies being placed on your device, you can do so by modifying the settings at
          our cookie setting center. Nevertheless, please consider that deleting and blocking cookies may have an impact
          on your user experience.
        </PrivacyPolicyPageBlockStyled>
        <PrivacyPolicyPageTitleStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          What types of cookies does the site use?
        </PrivacyPolicyPageTitleStyled>
        <PrivacyPolicyPageBlockStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          The cookies used on Edenex sites have been categorized as per the table below. However, it is important to
          note that not all cookies may be used in all jurisdictions or websites. A list of the categories of cookies
          used on this website is set out below.
        </PrivacyPolicyPageBlockStyled>
        <PrivacyPolicyPageTitleStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          Strictly Necessary cookies
        </PrivacyPolicyPageTitleStyled>
        <PrivacyPolicyPageBlockStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          These cookies are necessary for the website to function and cannot be switched off in our systems. They are
          usually only set in response to actions made by which amount to a request for services, such as setting your
          privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about
          these cookies, but some parts of the site will not when work. These cookies do not store any personally
          identifiable information.
        </PrivacyPolicyPageBlockStyled>
        <PrivacyPolicyPageTitleStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          Performance cookies
        </PrivacyPolicyPageTitleStyled>
        <PrivacyPolicyPageBlockStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          These cookies allow us to count visits and traffic sources so we can measure and improve the performance of
          our site. They help us to know which pages are the most and least popular and see how visitors move around the
          site. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these
          cookies we will not know when you have visited our site and will not be able to monitor its performance.
        </PrivacyPolicyPageBlockStyled>
        <PrivacyPolicyPageTitleStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          Functionality cookies
        </PrivacyPolicyPageTitleStyled>
        <PrivacyPolicyPageBlockStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          These cookies enable the website to provide enhanced functionality and personalisation. They may be set by us
          or by a third-party provider whose services we have added to our pages. If you don’t allow these cookies some
          services may not function properly.
        </PrivacyPolicyPageBlockStyled>
        <PrivacyPolicyPageTitleStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          Targeting cookies
        </PrivacyPolicyPageTitleStyled>
        <PrivacyPolicyPageBlockStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          Targeting cookies may be set through our site by our advertising partners. They can be used by these third
          parties to build a profile of your interests based on the browsing information they collect from you, which
          includes uniquely identifying your browser and terminal equipment. If you do not allow these cookies you will
          still see basic advertising on your browser that is generic and not based on your interests
        </PrivacyPolicyPageBlockStyled>
        <PrivacyPolicyPageBlockStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          If you have any questions now or during your visit, please submit your request through our
          developer@edenex.com.
          You can adjust your cookie settings at any time from our cookie setting center.
        </PrivacyPolicyPageBlockStyled>
      </PrivacyPolicyPageStyled>
    </WrapperContentStyled>
  );
};

export default TermsOfUse;
