import { Layout, notification } from 'antd';
import { Outlet, useNavigate } from 'react-router-dom';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { IconNotificationSuccess, LogoBig } from '../../ui/icons';
import { LayoutInnerLeftStyled, LayoutInnerRightStyled, LayoutInnerStyled, LayoutStyled } from './styles';
import { useResize } from '../../../hooks/useResize';
import { useTranslation } from 'react-i18next';
import { LanguageModal } from '../../ui/modalLanguage';
import { Header } from '../../ui/header';
import { ROUTER_PATH } from '../../../router/path';
import { TDecodedToken, useAuthState } from '../../../state/useAuthState';
import FadeWrapper from '../../FadeWrapper';
import { CloseIcon } from '../../../assets/img/icons/icons';
import { useCookiesCustom } from '../../../hooks/useCookieCustom';


const LayoutAuth = (): JSX.Element => {
  const isLoading = useAuthState((state) => state.isLoading);
  const serverTime = useAuthState((state) => state.serverTime);
  const setIsAuth = useAuthState((state) => state.setIsAuth);
  const isAuth = useAuthState((state) => state.isAuth);
  const getUserKC = useAuthState((state) => state.getUserKC);
  const userAPI = useAuthState((state) => state.userAPI);
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get('token');
  const navigate = useNavigate();
  const [resetTimestamp, setResetTimestamp] = useState<number | null>(null);
  const {cookie, setCookie, removeCookie} = useCookiesCustom();
  const { Content } = Layout;
  const { i18n, t } = useTranslation();
  const [isOpenLanguageModal, setIsOpenLanguageModal] = useState(false);
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const inviteToken = !!cookie.inviteToken ? cookie.inviteToken : '';
  const currentServerTimeFromCookie = cookie['currentServerTime'] ? Number(cookie['currentServerTime'])
    : 0;
  const [currentServerTime, setCurrentServerTime] = useState<number>(
    currentServerTimeFromCookie || serverTime,
  );

  const { isScreen767 } = useResize();

  useEffect(() => {
    setCollapsed(true);
    setIsOpenLanguageModal(false);
  }, [isScreen767]);


  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const setIsExitUser = (value: boolean) => {
    //Записываем в куки, что пользователь вышел из браузера
    setCookie('isExitUser', value);
  };

  useLayoutEffect(() => {
    const savedTimestamp = cookie.refreshTokenTimestamp;

    if (parseInt(savedTimestamp) > 0) {
      setResetTimestamp(parseInt(savedTimestamp));
    }

    const handleBeforeUnload = () => {
      const ResetTimestamp = resetTimestamp ? resetTimestamp.toString() : '0';
      const CurrentServerTime = currentServerTime.toString();

      setCookie('refreshTokenTimestamp', ResetTimestamp);
      setCookie('currentServerTime', CurrentServerTime);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [
    resetTimestamp,
    cookie.refreshTokenTimestamp,
    cookie.token,
    serverTime,
    currentServerTime,
  ]);

  useLayoutEffect(() => {
    if (!!cookie.token) {
      getUserKC({ session: cookie['oidc.user'] ?? '', setCookie });
    }
  }, [cookie.token]);

  useLayoutEffect(() => {
    const redirectUrl = localStorage.getItem('redirectUrl');

    // Если есть токен то пускать
    if (token) return;

    // Если есть сессия
    if (!!cookie.token) {

      if (!!redirectUrl && redirectUrl !== 'undefined') {

        // Если есть inviteToken
        if (!!inviteToken && inviteToken !== 'undefined') {

          notification.info({
            icon: <IconNotificationSuccess />,
            message: <span
              style={{
                fontSize: '16px',
                fontWeight: '700',
                color: '#FBFBFF',
              }}>{t('registerConfirmation.successMessage')}</span>,
            description: <span
              style={{ color: '#FBFBFF' }}>{t('descriptions.registrationInviteLogIn')}</span>,
            duration: 3,
            style: { background: '#2B2B36' },
            closeIcon: <CloseIcon />,
          });

          console.log('Auth Перенаправление на', `${ROUTER_PATH.FormsPages.CONFIRM_INVITE}?inviteToken=${inviteToken}&redirect_url=${redirectUrl}`);
          setTimeout(() => {
            navigate(`${ROUTER_PATH.FormsPages.CONFIRM_INVITE}?inviteToken=${inviteToken}&redirect_url=${redirectUrl}`);
          }, 4000);
        }

      } else if (!redirectUrl || redirectUrl === 'undefined' || redirectUrl === '') {

        // Если есть inviteToken
        if (!!inviteToken && inviteToken !== 'undefined') {
          console.log('Auth Перенаправление на', `${ROUTER_PATH.FormsPages.CONFIRM_INVITE}?inviteToken=${inviteToken}`);
          setTimeout(() => {
            navigate(`${ROUTER_PATH.FormsPages.CONFIRM_INVITE}?inviteToken=${inviteToken}`);
          }, 4000);
        }

        // Если нет inviteToken
        if (!inviteToken || inviteToken === 'undefined') {
          console.log('Auth Перенаправление на', ROUTER_PATH.AuthRoutes.ACCOUNT_SETTINGS_INDEX);
          setTimeout(() => {
            navigate(ROUTER_PATH.AuthRoutes.ACCOUNT_SETTINGS_INDEX);
          }, 4000);
        }

      }
    }

  }, [cookie.token, isAuth]);

  let layoutStyled =
    <>
      <FadeWrapper isVisible={isLoading}>
        <LayoutStyled>
          <Header toggleCollapsed={toggleCollapsed} />
          <LayoutInnerStyled $isScreen767={isScreen767}>
            {isScreen767 &&
              <>
                <LayoutInnerLeftStyled>
                  <LogoBig />
                </LayoutInnerLeftStyled>
              </>
            }
            <LayoutInnerRightStyled $isScreen767={isScreen767}>
              <Content>
                <Outlet />
                {!isScreen767 &&
                  <div style={{ color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {t('authorizationForms.languageSelection')}
                    <span
                      style={{
                        marginLeft: '10px',
                        cursor: 'pointer',
                        textTransform: 'uppercase',
                      }}
                      onClick={() => setIsOpenLanguageModal(true)}
                    >
                {i18n.language}
              </span>
                  </div>
                }
              </Content>
            </LayoutInnerRightStyled>
          </LayoutInnerStyled>
          {isOpenLanguageModal &&
            <LanguageModal
              open={isOpenLanguageModal}
              onCancel={() => setIsOpenLanguageModal(false)}
              title={t('authorizationForms.languageSelection')}
            />
          }
        </LayoutStyled>
      </FadeWrapper>
    </>;

  return layoutStyled;
};

export default LayoutAuth;
