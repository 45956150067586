import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { ConfigProvider } from 'antd';
import { mainTheme } from './components/ui/themes';
import './i18n';
import './styles.css';
import { FullScreenLoader } from './components/full-screen-loader';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
  <React.Suspense fallback={<FullScreenLoader />}>
    <ConfigProvider theme={mainTheme}>
      <App />
    </ConfigProvider>
  </React.Suspense>,
);
