import React from 'react';
import { Tag } from 'antd';
import Icon from '@ant-design/icons';
import {
  GreenCheckSafetyCardIcon,
  RedCrossSafetyCardIcon,
} from '../../../../assets/img/icons/icons';
import styled from 'styled-components';

interface IBasicTagProps {
  text: string;
  isConnected: boolean;
  noIcon?: boolean;
}

const BasicTag = ({
  text,
  isConnected,
  noIcon,
}: IBasicTagProps): JSX.Element => {

  const tagIcon = () => {
     return noIcon === true ? null : isConnected ? (
      <Icon component={GreenCheckSafetyCardIcon} />
    ) : (
      <Icon component={RedCrossSafetyCardIcon} />
    )
  }

  return (
    <BasicTagStyled icon={tagIcon()}>
      {text}
    </BasicTagStyled>
  );
};

const BasicTagStyled = styled(Tag)`
  border: none;
  color: white;
  display: flex;
  align-items: center;
  padding: 0;
`;

export { BasicTag };
