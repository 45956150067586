import i18n from 'i18next';
import { initReactI18next } from 'react-i18next'; // https://react.i18next.com/latest/using-with-hooks
import Backend from 'i18next-http-backend'; // adding lazy loading for translations, more information here: https://github.com/i18next/i18next-http-backend
import detector from 'i18next-browser-languagedetector'; // auto detect the user language, more information here: https://github.com/i18next/i18next-browser-languageDetector

i18n
  .use(Backend)
  .use(detector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en', 'ru'],
    // supportedLngs: ['en', 'ar', 'fr', 'jp', 'pt', 'ch', 'es', 'ru', 'hi'],
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json', // locale files path
    },
    defaultNS: 'common',
    // fallbackLng: ['en', 'ar', 'fr', 'jp', 'pt', 'ch', 'es', 'ru', 'hi'],
    fallbackLng: ['en', 'ru'],
    debug: true,
    react: {
      useSuspense: true,
    },
  });

export default i18n;
