function isValidJsonString(str: string): boolean {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

/**
 * Retrieves the value of a cookie by its name.
 *
 * @param {string} name - The name of the cookie to retrieve.
 * @returns {string | null | undefined} The value of the cookie if found, parsed to its appropriate type (object, array, boolean), or null if the document object is not available, or undefined if the cookie is not found.
 *
 * @example
 * // Assuming document.cookie is "user=JohnDoe; token=abc123"
 * const user = getCookieByName('user');
 * console.log(user); // "JohnDoe"
 *
 * @example
 * // Assuming document.cookie is "isLoggedIn=true"
 * const isLoggedIn = getCookieByName('isLoggedIn');
 * console.log(isLoggedIn); // true
 */
export const getCookieByName = (name: string): string | null | undefined => {
  if (typeof document === 'undefined') return null;

  const cookieString = document.cookie;
  const cookies = cookieString?.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    const [cookieName, cookieValue] = cookie.split('=');

    if (cookieName === name) {
      if (isValidJsonString(decodeURIComponent(cookieValue))) {
        return JSON.parse(decodeURIComponent(cookieValue));
      } else {
        return decodeURIComponent(cookieValue);
      }
    }
  }
};

/**
 * Sets a cookie with the specified name and value, with an expiration time of 10 years,
 * and attaches it to multiple domains.
 *
 * @template T - The type of the value to be stored in the cookie.
 * @param {string} name - The name of the cookie to set.
 * @param {T} value - The value of the cookie. It can be of any type and will be stringified if necessary.
 *
 * @example
 * // Setting a simple string cookie
 * setCookieByName('username', 'JohnDoe');
 *
 * @example
 * // Setting a cookie with an object value
 * setCookieByName('user', { name: 'John', age: 30 });
 *
 * @example
 * // Setting a cookie with a boolean value
 * setCookieByName('isLoggedIn', true);
 */
export const setCookieByName = <T>(name: string, value: T) => {
  const now = new Date();
  now.setFullYear(now.getFullYear() + 10); //тут можно устанавливать время жизни куки

  const expires = now.toUTCString();
  const encodedValue = encodeURIComponent(
    typeof value === 'string' ? value : JSON.stringify(value)
  );

  const domains = ['.extract-sweet.ru', 'localhost', '.edenex.com']; //к каким доменам цепляются куки

  domains.forEach((domain) => {
    document.cookie = `${name}=${encodedValue};expires=${expires};domain=${domain};path=/;`;
  });
};

/**
 * Deletes a cookie with the specified name from multiple domains.
 *
 * @param {string} name - The name of the cookie to delete.
 *
 * @example
 * // Deleting a cookie with the name 'username'
 * deleteCookieByName('username');
 */
export const deleteCookieByName = (name: string) => {
  const expires = 'Thu, 01 Jan 1970 00:00:00 GMT';
  const domains = ['.extract-sweet.ru', 'localhost', '.edenex.com'];

  domains.forEach((domain) => {
    document.cookie = `${name}=; expires=${expires}; domain=${domain}; path=/;`;
  });
};
