import { Button, Form } from 'antd';
import styled, { css } from 'styled-components';

export const FormButtonStyled = styled(Button)`
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  padding-top: 16px;
  row-gap: 8px;
  column-gap: 8px;
  width: 100%;
  height: 40px;
  background-image: linear-gradient(
    75.86deg,
    rgb(99, 32, 230) 6.84%,
    rgb(123, 89, 253) 99.53%
  );
  border-radius: 8px;
  border-top-style: none;
  border-right-style: none;
  border-bottom-style: none;
  border-left-style: none;
  border-top-color: rgba(255, 255, 255, 0);
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: rgb(251, 251, 255);
  margin-top: 32px;
  margin-right: 0;
`;

export const FormButtonCancelStyled = styled(Button)`
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  padding-top: 16px;
  row-gap: 8px;
  column-gap: 8px;
  width: 100%;
  height: 40px;
  background-image: linear-gradient( 80.47deg, rgb(24, 24, 31) 6.62%, rgb(45, 45, 58) 148.62% ),linear-gradient(25.41deg, rgb(110, 39, 248) 2.1%, rgb(32, 217, 192) 71.42%);
  border-radius: 8px;
  border-top-color: rgba(255, 255, 255, 0);
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: rgb(251, 251, 255);
  margin-top: 16px;
  margin-right: 0;
  border: 1px solid #6E27F8;
  &:hover{
    border: 1px solid #7B59FD;
  }
`;
export const FormBorderStyled = styled.div<{
  $isScreen767?: boolean;
  $isScreen991?: boolean;
}>`
  width: 360px;
  //max-width: 360px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px 40px 40px 31px;
  min-height: 150px;
  border-left-width: 9px;
  border-left-style: solid;
  border-left-color: transparent;
  background-image: linear-gradient(
      80.47deg,
      rgb(24, 24, 31) 6.62%,
      rgb(45, 45, 58) 148.62%
    ),
    linear-gradient(25.41deg, rgb(110, 39, 248) 2.1%, rgb(32, 217, 192) 71.42%);
  background-position-x: 0;
  background-position-y: 0;
  background-size: auto, auto;
  background-repeat: repeat, repeat;
  background-attachment: scroll, scroll;
  background-origin: padding-box, border-box;
  background-clip: padding-box, border-box;
  box-shadow: rgba(32, 217, 192, 0.15) -12px 13px 30px;
  border-radius: 16px;
  gap: 10px;
  margin-left: 30px;
  //margin-top: 100px;

  ${({ $isScreen991 }) =>
    !$isScreen991 &&
    css`
      width: 330px;
      padding: 25px 25px 25px 16px;
    `}

  ${({ $isScreen767 }) =>
    !$isScreen767 &&
    css`
      width: 100vw;
      max-width: none;
      box-shadow: none;
      background-image: none;
      padding: 20px;
      gap: 20px;
      margin-left: 0;
    `}

`;

export const FormTitleStyled = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  color: white;
  margin-bottom: 40px;
`;

export const FormSubTitleStyled = styled.div`
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: rgb(251, 251, 255);
  margin-bottom: 24px;
`;
export const ErrorCodeStyled = styled.div`
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #F16063;
  height: 20px;
  margin-top: -40px;
`;

export const FormTimeCodeStyled = styled.div`
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: rgb(251, 251, 255);
  margin-top: 12px;
`;

export const NewCodeStyled = styled.span`
  cursor: pointer;

  &:hover{
    color: #7EABFF;
  }
`;
export const FormTimeStyled = styled.span`
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #FBFBFF;
  margin-top: 12px;
  margin-left: 5px;
`;

export const FormFooterStyled = styled.div<{
  $isScreen767?: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: rgb(251, 251, 255);

  ${({ $isScreen767 }) =>
    !$isScreen767 &&
    css`
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      justify-content: center;
      width: 100%;
      margin-top: 20px;
    `}
`;
 export const FormStyled = styled(Form)`
  width: 100%;
`

export const BoldNotificationTitle = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color:#fff;
`
