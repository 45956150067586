import { i18n } from 'i18next';

const getLocalizedDate = (
  day: number,
  monthIndex: number,
  year: number,
  hours: number,
  minutes: string,
  i18n: i18n
): string => {
  const ruMonths = [
    'января',
    'февраля',
    'марта',
    'апреля',
    'мая',
    'июня',
    'июля',
    'августа',
    'сентября',
    'октября',
    'ноября',
    'декабря',
  ];

  const enMonths = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
  ];

  const language = i18n.language || window.localStorage.i18nextLng;

  if (language === 'ru') {
    return `${day} ${ruMonths[monthIndex]}, ${year} в ${hours}:${minutes}`;
  }

  return `${day} ${enMonths[monthIndex]}, ${year} in ${hours}:${minutes}`;
};

export default getLocalizedDate;
