import { RouteObject } from 'react-router-dom';
import { lazy } from 'react';
import { LoadComponent } from '../../../common/router';
import { ROUTER_PATH } from '../../path';
const SafetyIndexPage = LoadComponent(
  lazy(() => import('../../../pages/safety'))
);
const ConfirmEmail = LoadComponent(
  lazy(() => import('../../../pages/confirm-email/ConfirmEmail'))
);
const GoogleAuthenticator = LoadComponent(
  lazy(() => import('../../../pages/safety/2fa-google'))
);
const DevicesManagement = LoadComponent(
  lazy(() => import('../../../pages/safety/devices-management'))
);
const IpProtection = LoadComponent(
  lazy(() => import('../../../pages/safety/ip-protection'))
);
const ChangeEmailPage = LoadComponent(
  lazy(() => import('../../../pages/safety/change-email-page/ChangeEmailPage'))
);

const SAFETY_ROUTES: RouteObject[] = [
  { path: ROUTER_PATH.AuthRoutes.CHANGE_EMAIL, element: <ChangeEmailPage /> },
  { path: ROUTER_PATH.AuthRoutes.SAFETY_INDEX, element: <SafetyIndexPage /> },
  { path: ROUTER_PATH.AuthRoutes.SAFETY_2FA, element: <GoogleAuthenticator /> },
  {
    path: ROUTER_PATH.AuthRoutes.SAFETY_DEVICES,
    element: <DevicesManagement />,
  },
  {
    path: ROUTER_PATH.AuthRoutes.SAFETY_IP_PROTECTION,
    element: <IpProtection />,
  },
  {
    path: ROUTER_PATH.AuthRoutes.CONFIRM_EMAIL,
    element: <ConfirmEmail />,
  },
];

export { SAFETY_ROUTES };
