export const TG_SUPPORT = 'https://t.me/+izCXhGBxF0Y0ZGQ6';

type TCookieConf = {
  path: string,
  domain: string,
  secure: boolean,
  httpOnly: boolean,
  sameSite: boolean | 'lax' | 'none' | 'strict' | undefined,
}

export const COOKIE_CONFIG_PROD: TCookieConf = {
  path: '/',
  domain: '.edenex.com',
  secure: false,
  httpOnly: false,
  sameSite: 'lax',
}

export const COOKIE_CONFIG_DEV: TCookieConf = {
  path: '/',
  domain: '.extract-sweet.ru',
  secure: false,
  httpOnly: false,
  sameSite: 'lax',
}
