import { TOOLBAR_COMPONENTS as Components } from './_styles';
import { Typography } from 'antd';
import React, { useEffect } from 'react';
import StatusTagStatus from './StatusTagStatus';
import { useAuthState } from '../../../../state/useAuthState';
import StatusTagLastLogin from './StatusTagLastLogin';
import { ErrorBoundary } from '../../../../components/ErrorBoundary';
import i18n from 'i18next';
import { useNavigate } from 'react-router-dom';
import { useCookiesCustom } from '../../../../hooks/useCookieCustom';

interface IPageToolbarProps {
  title: string;
  subTitle: string;
}

const { Title } = Typography;

const PageToolbar = ({ title, subTitle }: IPageToolbarProps): JSX.Element => {
  const {removeCookie} = useCookiesCustom();
  const navigate = useNavigate();
  const userAPI = useAuthState(state => state.userAPI);
  const getUserProfileFromAPI = useAuthState(state => state.getUserProfileFromAPI);

  let lastLogin = userAPI.account.last_login;

  const language = i18n.language || window.localStorage.i18nextLng;

  const dayjs = require('dayjs');
  const localizedFormat = require('dayjs/plugin/localizedFormat');
  dayjs.extend(localizedFormat);

  const formattedDate = dayjs(lastLogin).locale(language === 'ru' ? 'ru' : 'en').format('MMMM D, YYYY [в] H:mm');

  useEffect(() => {
    getUserProfileFromAPI();
  }, []);

  return (
    <ErrorBoundary removeCookie={removeCookie} navigate={navigate}>
      <Components.ToolbarWrapper>
        <Components.Navigation>
          <Title
            level={2}
            style={{
              fontFamily: 'Inter, sans-serif',
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: '40px',
              lineHeight: '48px',
              display: 'flex',
              alignItems: 'center',
              margin: 0,
            }}
          >
            {title}
          </Title>

          <Title
            level={5}
            style={{
              fontWeight: 600,
              fontSize: '18px',
              margin: '24px 0',
            }}
          >
            {subTitle}
          </Title>

          <Components.Tags
            style={{
              gap: '24px',
            }}
          >
            <StatusTagStatus status={userAPI?.account?.status} />
            <StatusTagLastLogin date={formattedDate} />
          </Components.Tags>
        </Components.Navigation>
      </Components.ToolbarWrapper>
    </ErrorBoundary>
  );
};

export { PageToolbar };
