import { useEffect, useState } from 'react';
import { useResize } from '../../hooks/useResize';
import {
  LinkStyled,
  PrivacyPolicyPageBlockStyled,
  PrivacyPolicyPageStyled,
  PrivacyPolicyPageTitleStyled,
  TitleH1Styled,
  WrapperContentStyled,
} from './styles';
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = () => {
  // const { t } = useTranslation();
  const { isScreen1199, isScreen991, isScreen767, isScreen575 } = useResize();
  const [language, setLanguage] = useState<'en' | 'ru'>('en');
  const { i18n } = useTranslation();

  useEffect(() => {
    if (window.location.pathname.indexOf('/ru') === -1) {
      setLanguage('en');
    } else {
      setLanguage('ru');
    }
  }, []);

  useEffect(() => {
    const checkReflection = () => {
      const ht = document.querySelector('html');
      if (ht && i18n.language === 'ar') {
        ht.className = '';
      }
    };
    checkReflection();
  }, [i18n.language]);

  return (
    <WrapperContentStyled
      $isScreen1199={isScreen1199}
      $isScreen991={isScreen991}
      $isScreen767={isScreen767}
      $isScreen575={isScreen575}
    >
      <PrivacyPolicyPageStyled
        $isScreen991={isScreen991}
        $isScreen767={isScreen767}
        $isScreen575={isScreen575}
      >
        <TitleH1Styled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          {language === 'ru'
            ? 'Политика в отношении обработки персональных данных'
            : 'Privacy policy'}
          {/* {t("Политика в отношении обработки персональных данных")} */}
        </TitleH1Styled>

        <PrivacyPolicyPageTitleStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          Privacy Policy
        </PrivacyPolicyPageTitleStyled>
        <PrivacyPolicyPageBlockStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          Edenex (“Edenex”, “we”, or “us”) is committed to protecting the privacy of our customers, and we take our data
          protection responsibilities with the utmost seriousness.
          This Privacy Notice describes how Edenex collects and processes your personal data through the Edenex websites
          and applications that are referenced in this Privacy Notice. Edenex refers to an ecosystem comprising Edenex
          websites (whose domain names include but are not limited to <LinkStyled href={'https://www.edenex.com'}
                                                                                  target='_blank'>www.edenex.com</LinkStyled>),
          mobile applications, clients,
          applets and other applications that are developed to offer Edenex Services, and includes
          independently-operated platforms, websites and clients within the ecosystem.
          This Privacy Notice applies to all Personal data processing activities carried out by us, across platforms,
          websites, and departments of Edenex.
          To the extent that you are a customer or user of our services, this Privacy Notice applies together with any
          terms of business and other contractual documents, including but not limited to any agreements we may have
          with you.
          To the extent that you are not a relevant stakeholder, customer, or user of our services, but are using our
          website, this Privacy Notice also applies to you together with our Cookie Notice.
          This Notice should therefore be read together with our Cookie Notice, which provides further details on our
          use of cookies on the website. Our Cookie Notice can be accessed here.
        </PrivacyPolicyPageBlockStyled>
        <PrivacyPolicyPageTitleStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          Edenex Relationship with you
        </PrivacyPolicyPageTitleStyled>
        <PrivacyPolicyPageBlockStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          The Edenex operating entity you contract with determines the means and purposes of processing of your personal
          data in relation to the services provided to you.
          The Edenex companies may share your personal data with each other and if they do so they will use it
          consistently with this Privacy Notice.
        </PrivacyPolicyPageBlockStyled>
        <PrivacyPolicyPageTitleStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          What Personal Data does Edenex collect and process?
        </PrivacyPolicyPageTitleStyled>
        <PrivacyPolicyPageBlockStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          Personal data is data that identifies an individual or relates to an identifiable individual. This includes
          data you provide to us, data which is collected about you automatically, and data we obtain from third
          parties.
          Information you provide to us. To open an account and access our services, we'll ask you to provide us with
          some data about yourself. This data is either required by law (e.g., to verify your identity and comply with
          “Know Your Customer” obligations), necessary to provide the requested services (e.g., you will need to provide
          your email address in order to open your account), or is relevant for certain specified purposes, described in
          greater detail below. In some cases, if we add services and features you may be asked to provide us with
          additional data.
          Failure in providing the data required implies that Edenex will not be able to offer you our services.
        </PrivacyPolicyPageBlockStyled>
        <PrivacyPolicyPageBlockStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}>
          Information we collect from you automatically. To the extent permitted under the applicable law, we may
          collect certain types of information automatically, for example whenever you interact with us or use the
          services. This information helps us address customer support issues, improve the performance of our sites and
          services, maintain and or improve your user experience, and protect your account from fraud by detecting
          unauthorized access.
        </PrivacyPolicyPageBlockStyled>
        <PrivacyPolicyPageBlockStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}>
          Information we collect from our affiliates and third parties.
          From time to time, we may obtain information about you from our affiliates or third parties sources as
          required or permitted by applicable law.
        </PrivacyPolicyPageBlockStyled>
        <PrivacyPolicyPageTitleStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          Why does Edenex process my personal data? Which legal bases are we relying on for our collection and
          processing of your personal data?
        </PrivacyPolicyPageTitleStyled>
        <PrivacyPolicyPageBlockStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          Our primary purpose in collecting personal data is to provide our services in a secure, efficient, and smooth
          way. We generally use your personal data to deliver, provide, operate, our services, and for content and
          advertising, and for loss prevention and anti-fraud purposes. Below you’ll find an explanation on how we use
        </PrivacyPolicyPageBlockStyled>
        <PrivacyPolicyPageBlockStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          Why does Edenex process my Personal Data?
        </PrivacyPolicyPageBlockStyled>
        <PrivacyPolicyPageBlockStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          - Managing our contractual relationship with you. To create and maintain your account.
          This includes when we use your personal data to take and handle orders and process payments.
          The Category of Personal Data processed is Personal Identification Data, Institutional Information, Contact
          Information, Financial Information. The consequences of not processing your personal data for such purposes is
          the inability to open an account with us or the termination of your account where one is already open.
        </PrivacyPolicyPageBlockStyled>
        <PrivacyPolicyPageBlockStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          - To maintain legal and regulatory compliance
          Most of our core services such as the exchange services are subject to strict and specific laws and
          regulations requiring us to collect, use and store certain personal data and process Personal Identification
          identity information and in some cases Sensitive Personal Data[(including biometrics) (as detailed in section
          I) For example to comply with our Know Your Customer (“KYC”) obligations under applicable laws and
          regulations, and in particular to comply with Anti-Money Laundering laws and regulations.
          The Category of Personal Data is Personal Identification Data , Institutional Information, Sensitive and
          Biometric information, Government Identifiers, Contact Information, Financial Information. If you do not
          provide personal information required by law, you may be unable to open an account, or we may have to close
          your account where it is already opened.
          - Communicate with you on service and transaction- related matters.
          We use your personal data to communicate with you in relation to Edenex Services on administrative or
          account-related information. We will communicate with you to keep you updated about our Services for example,
          to inform you of relevant security issues, updates, or provide other transaction-related information. Without
          such communications, you may not be aware of important developments relating to your account that may affect
          how you can use our services. You may not opt-out of receiving critical service communications, such as emails
          or mobile notifications sent for legal or security purposes.
        </PrivacyPolicyPageBlockStyled>
        <PrivacyPolicyPageBlockStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          - To provide customer services:
          We process your personal data when you contact us in order to provide support with respect to questions,
          disputes, complaints, troubleshoot problems, etc.
          The Category of Personal Data processed is Personal Identification Data, Institutional Information,
          Transactional Information, Communications, Contact Information, Financial Information, Browsing Information,
          Usage Data.
        </PrivacyPolicyPageBlockStyled>
        <PrivacyPolicyPageBlockStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          Without processing your personal data for this purpose, we can’t respond to your requests.
        </PrivacyPolicyPageBlockStyled>
        <PrivacyPolicyPageBlockStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          - To promote safety, security, and integrity of our platform.
          We process your personal data in order to enhance security, monitor and verify identity or service access,
          combat malware or security risks and to comply with applicable security laws and regulations.
          We process your personal data to verify accounts and related activity, find and address violations of our
          Terms and Conditions, investigate suspicious activity, detect, prevent and combat unlawful behaviour, detect
          fraud, and maintain the integrity of our Services.
          The Category of Personal Data processed is Personal Identification Data, Institutional Information,
          Transactional Information, Contact Information, Financial Information, Browsing Information, Usage Data.
          Without processing your personal information, we may not be able to ensure the security of our Services.
          We use your personal data to provide functionality, analyse performance, fix errors, and improve the usability
          and effectiveness of Edenex Services.
        </PrivacyPolicyPageBlockStyled>
        <PrivacyPolicyPageBlockStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          - To promote safety, security, and integrity of our Services.
          Fraud prevention and detection and credit risks.
          The Category of Personal Data processed is Personal Identification Data, Institutional Information,
          Transactional Information, Contact Information, Financial Information, Browsing Information, Usage Data. We
          process Personal Identification Data to prevent and detect, prevent and mitigate fraud and abuse of our
          services and in order to protect you against account compromise or funds loss and in order to ensure the
          security of our users, Edenex services and others. We may also use scoring methods to assess and manage credit
          risks.
        </PrivacyPolicyPageBlockStyled>
        <PrivacyPolicyPageBlockStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          - To provide Edenex services.
          We process your personal data to provide the services to you and process your orders. For example, when you
          want to use the exchange service on our platform, we ask for certain information such as your identification,
          contact information, and payment information.
          The Category of Personal Data processed is Personal Identification Data, Institutional Information,
          Transactional Information, Contact Information, Financial Information, Browsing Information, Usage Data.
          We cannot provide you with services without such information.
        </PrivacyPolicyPageBlockStyled>
        <PrivacyPolicyPageBlockStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          - To improve our services.
          We process personal data to improve our services and for you to have a better user experience;
          The Category of Personal Data processed is Personal Identification Data, Institutional Information,
          Transactional Information, Browsing Information, Usage Data.
        </PrivacyPolicyPageBlockStyled>
        <PrivacyPolicyPageBlockStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          - To provide you with promotions
          We use your information to provide you with promotions, including offers, rewards, and other incentives for
          using our Services.
          The Category of Personal Data processed is Personal Identification Data, Institutional Information,
          Transactional Information, Browsing Information, Usage Data.
        </PrivacyPolicyPageBlockStyled>
        <PrivacyPolicyPageBlockStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          - To do research and innovate.
          We carry out surveys to learn more about your experience using our Services. We also use your information to
          support research and development and drive innovations of our Services and products. This information will
          also be used for Marketing purposes.
        </PrivacyPolicyPageBlockStyled>
        <PrivacyPolicyPageBlockStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          - Recommendations and personalisation.
          We use your personal information to recommend features and services that might be of interest to you, identify
          your preferences, and personalise your experience with Edenex services;
        </PrivacyPolicyPageBlockStyled>
        <PrivacyPolicyPageBlockStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          - To provide marketing communications to you.
          We use your information based on your consent to send you targeted marketing communications through email,
          mobile, in-app, and push notifications.
          We also use your information to carry out profiling for marketing purposes.
          The Category of Personal Data processed is Personal Identification Data, Institutional Information,
          Transactional Information, Browsing Information, Usage Data, Marketing and Research Information,
          Communications.
        </PrivacyPolicyPageBlockStyled>
        <PrivacyPolicyPageBlockStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          Where required by applicable law, we rely on your consent to process your personal information for marketing
          purposes. When you consent to processing your personal information for a specified purpose, you may withdraw
          your consent at any time and we will stop processing your personal information for that purpose. The
          withdrawal of consent does not affect the lawfulness of processing based on consent before its withdrawal
          Where we carry out profiling for marketing purposes, for example to establish what Services or promotions you
          may be interested in, this processing is based on legitimate interest
        </PrivacyPolicyPageBlockStyled>
        <PrivacyPolicyPageBlockStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          I. Processing is necessary for the purpose of our legitimate interest to produce, organise and host
          events. customers.
          II. In the case where you pay to participate to an Event, the processing is necessary for the
          performance of a contract of which you are a party.
          III. Processing is necessary for the purpose of our legitimate interest to promote the events, inform
          about the Event or to evaluate the performance of the Event.
          IV. We rely on your consent to process your personal data for marketing purposes. When you consent to
          processing your personal information for a specified purpose, you may withdraw your consent at any time and we
          will stop processing your personal information for that purpose. The withdrawal of consent does not affect the
          lawfulness of processing based on consent before its withdrawal.
        </PrivacyPolicyPageBlockStyled>
        <PrivacyPolicyPageBlockStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          Where required by applicable law, we rely on your consent to place cookies and similar technologies. When you
          consent to processing your personal information for a specified purpose, you may withdraw your consent at any
          time and we will stop processing your personal information for that purpose.
          The withdrawal of consent does not affect the lawfulness of processing based on consent before its withdrawal
        </PrivacyPolicyPageBlockStyled>
        <PrivacyPolicyPageBlockStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          Processing is necessary to comply with our legal obligations under applicable laws and regulations
          We may also rely on legitimate interests in responding to legal requests where we are not compelled by
          applicable law but have a good faith belief it is required by law in the relevant jurisdiction.
        </PrivacyPolicyPageBlockStyled>
        <PrivacyPolicyPageBlockStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          Automated individual decision-making, including profiling. Edenex does not rely solely on automated tools to
          help determine whether a transaction or a customer account presents a fraud or legal risk.
        </PrivacyPolicyPageBlockStyled>
        <PrivacyPolicyPageTitleStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          Can Children Use Edenex Services?
        </PrivacyPolicyPageTitleStyled>
        <PrivacyPolicyPageBlockStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          Edenex does not allow anyone under the age of 18 to use Edenex Services and does not knowingly collect
          personal information from children under 18.
        </PrivacyPolicyPageBlockStyled>
        <PrivacyPolicyPageTitleStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          What About Cookies and Other Identifiers?
        </PrivacyPolicyPageTitleStyled>
        <PrivacyPolicyPageBlockStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          We use cookies and similar tools to enhance your user experience, provide our services, enhance our marketing
          efforts and understand how customers use our services so we can make improvements. Depending on applicable
          laws in the region you are located in, the cookie banner on your browser will tell you how to accept or refuse
          cookies. A copy of our cookie policy is available <LinkStyled
          href={`${window.location.origin}/terms`} target='_blank'>here</LinkStyled>
        </PrivacyPolicyPageBlockStyled>
        <PrivacyPolicyPageTitleStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          How and Why We Share your Personal Data?
        </PrivacyPolicyPageTitleStyled>
        <PrivacyPolicyPageBlockStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          We may share your Personal Data with third parties (including other Edenex entities) if we believe that
          sharing your Personal InforDatamation is in accordance with, or required by, any contractual relationship with
          you or us, applicable law, regulation or legal process. When sharing your Personal Data with other Edenex
          entities, we will use our best endeavours to ensure that such entities are either subject to this Privacy
          Notice, or follow practices at least as protective as those described in this Privacy Notice. For example,
          depending on where you reside and the entity responsible for running KYC checks. For more information please
          refer to Section 1.
          We may also share personal data with the following persons or in the following circumstances:
        </PrivacyPolicyPageBlockStyled>
        <PrivacyPolicyPageBlockStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          Affiliates: Personal data that we process and collect may be transferred between Edenex companies as a normal
          part of conducting business and offering our Services to you. See Section 1 “Edenex Relationship with you” and
          Section 2. “What Personal Data does Edenex collect and process?”.
          Third party service providers: We employ other companies and individuals to perform functions on our behalf.
          Examples include analysing data, providing marketing assistance, processing payments, transmitting content,
          and assessing and managing credit risk. These third-party service providers only have access to personal
          information needed to perform their functions but may not use it for other purposes. Further, they must
          process the personal information in accordance with our contractual agreements and only as permitted by
          applicable data protection laws.
          Legal Authorities: We may be required by law or by Court to disclose certain information about you or any
          engagement we may have with you to relevant regulatory, law enforcement and/or other competent authorities. We
          will disclose information about you to legal authorities to the extent we are obliged to do so according to
          the law. We may also need to share your information in order to enforce or apply our legal rights or to
          prevent fraud.
          Business transfers: As we continue to develop our business, we might sell or buy other businesses or services.
          In such transactions, user information generally is one of the transferred business assets but remains subject
          to the promises made in any pre-existing Privacy Notice (unless, of course, the user consents otherwise).
          Also, in the unlikely event that Edenex or substantially all of its assets are acquired by a third party, user
          information will be one of the transferred assets.
          Protection of Edenex and others: We release accounts and other personal information when we believe release is
          appropriate to comply with the law or with our regulatory obligations; enforce or apply our Terms of Use and
          other agreements; or protect the rights, property or safety of Edenex, our users or others. This includes
          exchanging information with other companies and organisations for fraud protection and credit risk reduction.
        </PrivacyPolicyPageBlockStyled>
        <PrivacyPolicyPageTitleStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          How Secure is My Information?
        </PrivacyPolicyPageTitleStyled>
        <PrivacyPolicyPageBlockStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          We design our systems with your security and privacy in mind. We have appropriate security measures in place
          to prevent your information being accidentally lost, used or accessed in an unauthorised way, altered or
          disclosed. We work to protect the security of your personal data during transmission and while stored by using
          encryption protocols and softwares. We maintain physical, electronic and procedural safeguards in connection
          with the collection, storage and disclosure of your personal data. In addition, we limit access to your
          personal data to those employees, agents, contractors and other third parties who have a business need to
          know.
          Our security procedures mean that we may ask you to verify your identity to protect you against unauthorised
          access to your account. We recommend using a unique password for your Edenex account that is not utilized for
          other online accounts and to sign off when you finish using a shared computer.
        </PrivacyPolicyPageBlockStyled>
        <PrivacyPolicyPageTitleStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          What About Advertising?
        </PrivacyPolicyPageTitleStyled>
        <PrivacyPolicyPageBlockStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          In order for us to provide you with the best user experience, we may share your personal data with our
          marketing partners for the purposes of targeting, modelling, and/or analytics as well as marketing and
          advertising. You have a right to object at any time to processing of your personal data for direct marketing
          purposes.
        </PrivacyPolicyPageBlockStyled>
        <PrivacyPolicyPageTitleStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          What Rights Do I Have?
        </PrivacyPolicyPageTitleStyled>
        <PrivacyPolicyPageBlockStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          Subject to applicable law, as outlined below, you have a number of rights in relation to your privacy and the
          protection of your personal data. You have the right to request access to, correct, and delete your personal
          data, and to ask for data portability. You may also object to our processing of your personal data or ask that
          we restrict the processing of your personal data in certain instances. In addition, when you consent to our
          processing of your personal data for a specified purpose, you may withdraw your consent at any time. If you
          want to exercise any of your rights please contact us using e-mail developer@edenex.com. These rights may be
          limited in some situations - for example, where we can demonstrate we have a legal requirement to process your
          personal data.
        </PrivacyPolicyPageBlockStyled>
        <PrivacyPolicyPageBlockStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          Right to access: you have the right to obtain confirmation that your personal data are processed and to obtain
          a copy of it as well as certain information related to its processing;
          Right to rectify: you can request the rectification of your personal data which are inaccurate, and also add
          to it. You can also change your personal information in your account at any time.
          Right to delete: you can, in some cases, have your personal data deleted;
          Right to object: you can object, for reasons relating to your situation, to the processing of your personal
          data. For instance, you have the right to object where we rely on legitimate interest or where we process your
          data for direct marketing purposes;
          Right to restrict processing: You have the right, in certain cases, to temporarily restrict the processing of
          your personal data by us, provided there are valid grounds for doing so. We may continue to process your
          personal data if it is necessary for the defense of legal claims, or for any other exceptions permitted by
          applicable law;
          Right to portability: in some cases, you can ask to receive your personal data which you have provided to us
          in a structured, commonly used and machine-readable format, or, when this is possible, that we communicate
          your personal data on your behalf directly to another data controller;
          Right to withdraw your consent: for processing requiring your consent, you have the right to withdraw your
          consent at any time. Exercising this right does not affect the lawfulness of the processing based on the
          consent given before the withdrawal of the latter;
          Right to lodge a complaint with the relevant data protection authority: We hope that we can satisfy any
          queries you may have about the way in which we process your personal data. However, if you have unresolved
          concerns, you also have the right to complain to the data protection authority in the location in which you
          live, work or believe a data protection breach has occurred.
          If you have any questions or objection as to how we collect and process your personal data, please contact us
          using e-mail developer@edenex.com.
        </PrivacyPolicyPageBlockStyled>
        <PrivacyPolicyPageTitleStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          How Long Does Edenex Keep My Personal Data?
        </PrivacyPolicyPageTitleStyled>
        <PrivacyPolicyPageBlockStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          We keep your personal data to enable your continued use of Edenex Services, for as long as it is required in
          order to fulfill the relevant purposes described in this Privacy Notice, and as may be required by law such as
          for tax and accounting purposes, compliance with Anti-Money Laundering laws, or to resolve disputes and/or
          legal claims or as otherwise communicated to you.
          While retention requirements vary by jurisdiction, information about our typical retention periods for
          different aspects of your personal data are described below.
        </PrivacyPolicyPageBlockStyled>
        <PrivacyPolicyPageBlockStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          Personal Identifiable Data collected to comply with our legal obligations under financial or anti-money
          laundering laws may be retained after account closure for as long as is required under such laws.
          Contact Information such as your name, email address and telephone number for marketing purposes is retained
          on an ongoing basis and until you (a) unsubscribe, or we (b) delete your account. Thereafter we will add your
          details to an unsubscribed list to ensure we do not inadvertently market to you.
          Content that you post on our website such as support desk comments, photographs, videos, blog posts, and other
          content may be kept after you close your account for audit and crime prevention purposes.
          Recording of voice calls with you may be kept for a period of up to six years, for audit/ compliance purposes
          and to resolve disputes and/or legal claims.
          Information collected via cookies, web page counters and other analytics tools is kept for a period of up to
          one year from the date of the collection of the cookie of the relevant cookie.
        </PrivacyPolicyPageBlockStyled>
        <PrivacyPolicyPageTitleStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          Contact Information
        </PrivacyPolicyPageTitleStyled>
        <PrivacyPolicyPageBlockStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          Our data protection officer can be contacted using mail at developer@edenex.com and will work to address any
          questions or issues that you have with respect to the collection and processing of your personal data.
        </PrivacyPolicyPageBlockStyled>
        <PrivacyPolicyPageTitleStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          Notices and Revisions
        </PrivacyPolicyPageTitleStyled>
        <PrivacyPolicyPageBlockStyled
          $isScreen991={isScreen991}
          $isScreen767={isScreen767}
          $isScreen575={isScreen575}
        >
          If you have any concerns about privacy at Edenex, please contact us, and we will try to resolve it. You also
          have the right to contact your local Data Protection Authority.
          Our business changes regularly, and our Privacy Notice may change also. You should check our websites
          frequently to see recent changes. Unless stated otherwise, our current Privacy Notice applies to all
          information that we have about you and your account.
        </PrivacyPolicyPageBlockStyled>
      </PrivacyPolicyPageStyled>
    </WrapperContentStyled>
  );
};

export default PrivacyPolicy;
