import { RouteObject } from 'react-router-dom';
import { ROUTER_PATH } from '../../path';
import AccountSettingsIndexPage from '../../../pages/account-settings';

const ACCOUNT_SETTINGS_ROUTES: RouteObject[] = [
  {
    path: ROUTER_PATH.AuthRoutes.ACCOUNT_SETTINGS_INDEX,
    element: <AccountSettingsIndexPage />,
  },
];

export { ACCOUNT_SETTINGS_ROUTES };
