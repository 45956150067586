import { ROUTER_PATH } from '../../path';
import { RouteObject } from 'react-router-dom';
import LoginPage from '../../../pages/login/Login';
import Register from '../../../pages/register/Register';
import RecoveryPassword from '../../../pages/recovery-password/RecoveryPassword';
import LayoutAuth from '../../../components/tools/layoutAuth/LayoutAuth';
import RegisterConfirm from '../../../pages/register-confirm/RegisterConfirm';
import ConfirmInvite from '../../../pages/confirm-invite/ConfirmInvite';
import PrivacyPolicy from '../../../pages/privacy-policy/PrivacyPolicy';

const FORMS_ROUTES: RouteObject[] = [
  {
    path: '/',
    element: <LayoutAuth />,

    children: [
      {
        path: ROUTER_PATH.FormsPages.LOGIN,
        element: <LoginPage />,
      },
      {
        path: ROUTER_PATH.FormsPages.REGISTER,
        element: <Register />,
      },
      {
        path: ROUTER_PATH.FormsPages.RECOVER_PASSWORD,
        element: <RecoveryPassword />,
      },
      {
        path: ROUTER_PATH.FormsPages.REGISTER_CONFIRM,
        element: <RegisterConfirm />,
      },
      {
        path: ROUTER_PATH.FormsPages.CONFIRM_INVITE,
        element: <ConfirmInvite />,
      },
    ],
  },
];

export { FORMS_ROUTES };
