import { ErrorBoundary } from '../../components/ErrorBoundary';
import React, { Suspense, useEffect, useState } from 'react';
import {
  FormBorderStyled,
  FormButtonStyled,
  FormFooterStyled,
  FormStyled,
  FormSubTitleStyled,
  FormTitleStyled,
} from '../../common/styles';
import { useNavigate } from 'react-router-dom';
import { ROUTER_PATH } from '../../router/path';
import { Button, Form, Input } from 'antd';
import { useRecoveryState } from '../../state/useRecoveryState';
import { passwordValidator } from '../../shared/helpers/validators';
import FormPasswordInput from '../../components/ui/form-password-input/FormPasswordInput';
import { useResize } from '../../hooks/useResize';
import { useTranslation } from 'react-i18next';
import { useAuthState } from '../../state/useAuthState';
import { useCookiesCustom } from '../../hooks/useCookieCustom';

const RecoveryPassword = () => {
  const {removeCookie} = useCookiesCustom();
  const logout = useAuthState((state) => state.logout);
  const navigate = useNavigate();
  const [formStep1] = Form.useForm();
  const [formStep3] = Form.useForm();
  const setEmail = useRecoveryState((state) => state.setEmail);
  const passwordForget = useRecoveryState((state) => state.passwordForget);
  const step = useRecoveryState((state) => state.step);
  const email = useRecoveryState((state) => state.email);
  const setPassword = useRecoveryState((state) => state.setPassword);
  const setPasswordConfirmation = useRecoveryState((state) => state.setPasswordConfirmation);
  const recoveryPassword = useRecoveryState((state) => state.recoveryPassword);
  const setStep = useRecoveryState((state) => state.setStep);
  const reset = useRecoveryState((state) => state.reset);
  const [queryToken, setQueryToken] = useState('');
  const [validState, setValidState] = React.useState<string[]>([]);
  const { isScreen767, isScreen991 } = useResize();
  const { t } = useTranslation();

  const navToLogin = () => {
    navigate(ROUTER_PATH.FormsPages.LOGIN);
    reset();
  };

  const getQueryToken = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const registerStep = localStorage.getItem('registerStep');
    const token = urlParams.get('token');
    if (!!token) {
      setQueryToken(token);
      setStep(3);
    } else if (!!registerStep?.length) {
      setStep(Number(registerStep));
    }
  };


  const handle1Step = () => {
    formStep1.validateFields().then(() => {
      passwordForget()
        .then(() => {
          setStep(2);
          localStorage.setItem('recoveryStep', String(2));
        })
        .catch((err) => {
          if (err.data.message.includes('Not found account')) {
            formStep1.setFields([{
              name: 'email',
              errors: [t('authorizationForms.invalidEmail')],
            }]);
          }
        });
    });
  };

  let hideEmail = function(email: string) {
    return email?.replace(
      /(.{2})(.*)(?=@)/,
      function(gp1: string, gp2: string, gp3: string) {
        for (let i = 0; i < gp3.length; i++) {
          gp2 += '*';
        }
        return gp2;
      },
    );
  };

  const handle3Step = () => {
    formStep3.validateFields()
      .then(() => {
        recoveryPassword({ token: queryToken }).then();
        setStep(4);
      })
      .catch((err) => {
        if (err.errorFields.some((error: any) => error.errors.includes(t('authorizationForms.passwordsDoesntMatch')))) {
          formStep3.setFields([{
            name: 'password',
            errors: [...formStep3.getFieldError('password').filter(err => err !== t('authorizationForms.passwordsDoesntMatch')), t('authorizationForms.passwordsDoesntMatch')],
          }]);
        }
        // else {
        //   setStep(4);
        // }
      });
  };
  useEffect(() => {
    getQueryToken();
  }, []);

  return (
    <ErrorBoundary logout={logout} navigate={navigate} removeCookie={removeCookie}>
      {[1, 2].includes(step) && (
        <Suspense fallback={'Loading...'}>
          <FormBorderStyled $isScreen767={isScreen767} $isScreen991={isScreen991}>
            <FormTitleStyled>
              {t('authorizationForms.restorePassword')}
            </FormTitleStyled>
            {step === 1 && (
              <FormSubTitleStyled>
                {t('authorizationForms.restoreAccountEnterEmail')}
              </FormSubTitleStyled>
            )
            }
            {step === 2 && (
              <FormSubTitleStyled>
                {t('authorizationForms.emailAddress')} {' '} {hideEmail(email)} {' '} {t('authorizationForms.linkSentResetPassword')}
              </FormSubTitleStyled>

            )
            }
            <FormStyled layout={'vertical'} requiredMark={false} form={formStep1}>
              <Form.Item
                label={t('authorizationForms.enterEmail')}
                name='email'
                rules={[
                  { type: 'email', message: `${t('authorizationForms.invalidEmailFormat')}` },
                  { required: true, message: `${t('authorizationForms.enterEmail')}` },
                ]}
              >
                <Input
                  placeholder={`${t('authorizationForms.email')}`}
                  disabled={step === 2}
                  onChange={(e) => setEmail(e.target.value)}
                  onPressEnter={handle1Step}
                  style={{ color: '#fff' }}

                />
              </Form.Item>
              <FormButtonStyled onClick={handle1Step}>{t('authorizationForms.restoreAccess')}</FormButtonStyled>
            </FormStyled>
            <FormFooterStyled>
              <Button type={'ghost'} onClick={navToLogin}>{t('authorizationForms.backLogin')}</Button>
            </FormFooterStyled>
          </FormBorderStyled>
        </Suspense>
      )}
      {
        step === 3 && (
          // Если есть токен, то ставить шаг 3
          <Suspense fallback={'Loading...'}>
            <FormBorderStyled $isScreen767={isScreen767} $isScreen991={isScreen991}>
              <FormTitleStyled>
                {t('authorizationForms.restorePassword')}
              </FormTitleStyled>
              <FormStyled layout={'vertical'} requiredMark={false} form={formStep3}>
                <Form.Item
                  label={t('authorizationForms.newPassword')}
                  name='password'
                  rules={[
                    { required: true, message: t('authorizationForms.newPassword').toString() },
                    {
                      validator: (_, value) =>
                        passwordValidator(value, setValidState),
                      message: t('authorizationForms.incorrectPassword').toString(),
                    },
                  ]}
                  style={{ marginBottom: 24 }}
                >
                  <FormPasswordInput
                    validState={validState}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Item>

                <Form.Item
                  label={t('authorizationForms.repeatPassword')}
                  name='password_repeat'
                  rules={[
                    { required: true, message: t('authorizationForms.repeatPassword').toString() },
                    ({ getFieldValue, setFields, getFieldError }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          setFields([{
                            name: 'password',
                            errors: getFieldError('password').filter(err => err !== t('authorizationForms.passwordsDoesntMatch')),
                          }]);
                          return Promise.resolve();
                        }
                        setFields([{
                          name: 'password',
                          errors: [...getFieldError('password').filter(err => err !== t('authorizationForms.passwordsDoesntMatch')), t('authorizationForms.passwordsDoesntMatch')],
                        }]);
                        return Promise.reject(new Error(t('authorizationForms.passwordsDoesntMatch').toString()));
                      },
                    }),
                  ]}
                  style={{ marginBottom: 32 }}
                >
                  <Input.Password
                    placeholder={t('authorizationForms.password').toString()}
                    onChange={(e) => setPasswordConfirmation(e.target.value)}
                    onPressEnter={handle3Step}
                  />
                </Form.Item>
                <FormButtonStyled onClick={handle3Step}>{t('authorizationForms.restoreAccess')}</FormButtonStyled>
              </FormStyled>
              <FormFooterStyled>
                <Button type={'ghost'} onClick={navToLogin}>{t('authorizationForms.backLogin')}</Button>
              </FormFooterStyled>
            </FormBorderStyled>
          </Suspense>
        )
      }
      {
        step === 4 && (
          <Suspense fallback={'Loading...'}>
            <FormBorderStyled $isScreen767={isScreen767} $isScreen991={isScreen991}>
              <FormTitleStyled>
                {t('authorizationForms.passwordChangedSuccessfully')}
              </FormTitleStyled>
              <FormSubTitleStyled>
                {t('authorizationForms.youSuccessfullyChangedPassword')}
              </FormSubTitleStyled>
              <FormButtonStyled onClick={navToLogin}>{t('authorizationForms.loginAccount')}</FormButtonStyled>
            </FormBorderStyled>
          </Suspense>
        )
      }
    </ErrorBoundary>
  );
};

export default RecoveryPassword;
