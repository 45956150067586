import { Checkbox, ConfigProvider } from 'antd';
import styled from 'styled-components';
import { ReactNode } from 'react';

type TCustomCheckbox = {
  label: string | ReactNode;
  checked?: boolean;
  disabled?: boolean;
  onChange?: () => void;
  value?: boolean;
};

const CustomCheckbox = ({
  label,
  checked,
  disabled,
  onChange,
  value,
  ...props
}: TCustomCheckbox) => {
  return (
    <ConfigProvider
      theme={{
        components:{
          Checkbox:{
            colorPrimary: '#6D38EF',
            borderRadiusSM: 4,
            colorPrimaryBg:'pink',
            colorBgContainer: 'transparent',
            lineWidth: 1,
            colorBorder: 'white',
            colorPrimaryHover: '#9469fa',
            colorText: 'white',
            fontSize: 12,
            lineHeight:1.25
          }
        }
      }}
    >
      <Checkbox disabled={disabled} onChange={onChange} {...props}>
        {label}
      </Checkbox>
    </ConfigProvider>
  );
};

const CustomCheckboxLabelStyled = styled.div`
  font-family: Inter, sans-serif;
  font-size: 12px;
  line-height: 15px;
  color: rgb(251, 251, 255);
  margin: 0 0 0 8px;
  width: 100%;
  max-width: 450px;
`;

export default CustomCheckbox;
