import { default as baseAxios } from 'axios';
import { API_URL } from '../packages/keycloak-client/constants';
import { useAuthState } from '../state/useAuthState';
import { getCookieByName } from './helpers/controlCookies';

export const axios = baseAxios.create({ baseURL: API_URL });

axios.interceptors.response.use(
  (res) => {
    return res;
  },
  async (error) => {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      if (
        error.response.data.message === "TokenException: [Keycloak Guard] Expired token" ||
        error.response.data.error === "TokenException: [Keycloak Guard] Expired token"
      ) {

        const originalRequest = error.config;
        return await useAuthState.getState().refreshToken(originalRequest);
      }
    }

    return Promise.reject(error);
  }
);

axios.interceptors.request.use((config) => {
  if (getCookieByName('token')) {
    config.headers.Authorization = `Bearer ${getCookieByName('token')}`.replace(
      'Bearer Bearer',
      'Bearer'
    );
  }

  config.headers['Content-Type'] = 'application/json';

  return config;
});

