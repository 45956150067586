import React from 'react';
import styled from 'styled-components';

type TPropsErrorBoundary = {
  children: React.ReactNode,
  logout?: ({ navigate, removeCookie }: any) => void,
  navigate: any,
  removeCookie: any,
}

export class ErrorBoundary extends React.Component<TPropsErrorBoundary, { hasError: boolean }> {
  constructor(props: TPropsErrorBoundary) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  // TODO: Исправить 301 TOO MANY RENDERS
  // componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
  //   if (!!this.props.logout) {
  //     console.log('ErrorBoundary componentDidCatch', error, errorInfo);
  //     this.props?.logout({ navigate: this.props?.navigate, removeCookie: this.props?.removeCookie });
  //   }
  // }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorTextStyled>Something went wrong.</ErrorTextStyled>;
    }

    return this.props.children;
  }
}

const ErrorTextStyled = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`;
