import { devtools } from 'zustand/middleware';
import create from 'zustand';
import { produce } from 'immer';
import { EDENEX_ACCOUNT_URL } from '../packages/keycloak-client/constants';
import { axios } from '../shared/exios';

type TRecoveryState = {
  setEmail: (value: string) => void;
  email: string;
  password: string;
  passwordConfirmation: string;
  setPasswordConfirmation: (value: string) => void;
  recoveryPassword: ({ token }: TRecoveryPassword) => Promise<void>;
  passwordForget: () => Promise<void | Error>;
  setPassword: (value: string) => void;
  reset: () => void;
  step: number;
  setStep: (value: number) => void;
};

type TRecoveryPassword = {
  token: string
}

export const useRecoveryState = create<TRecoveryState>()(
  devtools(
    (set) => ({
      step: 1,
      email: '',
      password: '',
      passwordConfirmation: '',
      setStep: (value: number) => {
        set(
          produce((draft) => {
            draft.step = value;
          }), false, {
            type: 'useRecoveryState => setStep',
          },
        );
      },
      setPassword: (value: string) => {
        set(
          produce((draft) => {
            draft.password = value;
          }), false, {
            type: 'useRecoveryState => setPassword',
          },
        );
      },
      setPasswordConfirmation: (value: string) => {
        set(
          produce(
            (draft) => {
              draft.passwordConfirmation = value;
            },
          ), false, {
            type: 'useRecoveryState => setPasswordConfirmation',
          },
        );
      },
      setEmail: (value: string) => {
        set(
          produce((draft) => {
            draft.email = value;
          }), false, {
            type: 'useRecoveryState => setEmail',
          },
        );
      },
      passwordForget: async () => {
        try {
          // Первый запрос восстановления пароля
          const res = await axios.post(`${EDENEX_ACCOUNT_URL}/edenex-account/api/password-forget`, {
            email: useRecoveryState.getState().email,
          });
          console.log('passwordForget res', res);
          set(
            produce((draft) => {
              draft.setStep(3);
              localStorage.setItem('recoveryStep', String(3));
            }),
          );
        } catch (e: any) {
          console.error('passwordForget error', e);
          return Promise.reject(e.response.data);
        }
      },
      recoveryPassword: async ({ token }: TRecoveryPassword) => {
        try {
          // Второй запрос восстановления пароля
          const res = await axios.post(`${EDENEX_ACCOUNT_URL}/edenex-account/api/password-recovery`, {
            token, // Из письма
            password: useRecoveryState.getState().password,
            password_confirmation: useRecoveryState.getState().passwordConfirmation,
          });
          console.log('recoveryPassword res', res);
          set(
            produce((draft) => {
              draft.setStep(4);
              localStorage.setItem('recoveryStep', String(4));
            }),
          );
        } catch (e) {
          console.log('recoveryPassword error', e);
          set(
            produce((draft) => {
              draft.setStep(4);
              localStorage.setItem('recoveryStep', String(4));
            }),
          );
        }
      },
      reset: () => {
        set(
          produce((draft) => {
            draft.step = 1;
            draft.email = '';
            draft.password = '';
            draft.passwordConfirmation = '';
            localStorage.removeItem('recoveryStep');
          }), false, {
            type: 'useRecoveryState => reset',
          },
        );
      },
    }), {
      anonymousActionType: 'useRecoveryState action',
      name: 'useRecoveryState',
    }),
);
