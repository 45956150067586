import styled from 'styled-components';

const TOOLBAR_COMPONENTS = {
  ToolbarWrapper: styled.div`
    display: flex;
    width: calc(100vw - 304px);
    justify-content: space-between;
    margin-top: 50px;
    margin-bottom: 40px;
  `,
  Navigation: styled.div`
    display: flex;
    flex-direction: column;
  `,
  InfoBlock: styled.div`
    display: flex;
  `,
  ChartWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 56px;
  `,
  Tags: styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  `,
};

export { TOOLBAR_COMPONENTS };
