import type { FC, NamedExoticComponent } from 'react';
import { memo, Suspense } from 'react';
import type { RouteObject } from 'react-router-dom';
import { BrowserRouter, useRoutes } from 'react-router-dom';
import {FullScreenLoader} from '../../components/full-screen-loader'

/**
 * Router provider
 */

interface IRouterProps {
  routes: RouteObject[];
}

const MakeRoutes: FC<Pick<IRouterProps, 'routes'>> = ({ routes }) => (
  <>{useRoutes(routes)}</>
);

const RouterProvider: FC<IRouterProps> = ({ routes }) => {
  return (
    <BrowserRouter>
      <MakeRoutes routes={routes} />
    </BrowserRouter>
  );
};

const LoadComponent = (Component: FC): NamedExoticComponent<any> =>
  memo((props: object) => (
    <>
      <Suspense fallback={<FullScreenLoader />}>
        <Component {...props} />
      </Suspense>
    </>
  ));

export { LoadComponent, RouterProvider };
