import { RouteObject } from 'react-router-dom';
import { ROUTER_PATH } from './path';
import { LoadComponent } from '../common/router';
import { lazy } from 'react';
import { APP_ROUTES } from './routes/app';
import { FORMS_ROUTES } from './routes/app/forms';
import PrivacyPolicy from '../pages/privacy-policy/PrivacyPolicy';
import TermsOfUse from '../pages/privacy-policy/TermsOfUse';

const NotFoundPage = LoadComponent(
  lazy(() => import('../pages/errors/not-found/index'))
);
const Test = LoadComponent(lazy(() => import('../pages/test/test')));

const ROUTES: RouteObject[] = [
  ...FORMS_ROUTES,
  ...APP_ROUTES,
  { path: 'test', element: <Test /> },
  {
    path: '*',
    element: <NotFoundPage />,
  },
  {
    path: ROUTER_PATH.FormsPages.PRIVACY_POLICY,
    element: <PrivacyPolicy />,
  },
  {
    path: ROUTER_PATH.FormsPages.TERMS_OF_USE,
    element: <TermsOfUse />,
  },
  { path: ROUTER_PATH.ErrorPages.NOT_FOUND, element: <NotFoundPage /> },
];

export { ROUTES };
