import styled from 'styled-components';
import { Button } from 'antd';

export const HeaderInnerStyled= styled.div`
  width: 100%;
  height: 80px;
  position: fixed;
  z-index: 100;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const WrapperLogoMiniStyled = styled.span`
  margin: 0 30px;
`;

export const LinksHeaderStyled= styled.span`
  width: 400px;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  margin-left: 30px;
`;

export const LinkItemHeaderStyled= styled.span`
  -webkit-box-align: center;
  align-items: center;
  color: rgb(195, 195, 206);
  font-weight: 500;
  font-size: 16px;
  text-decoration: none;
  font-family: Inter, sans-serif;
  cursor: pointer;
  display: inline-block;
  margin-right: 40px;
  &:hover {
    color: #fff;
  }
`;

export const WrapperLanguageSelectStyled= styled.div`
  margin-right: 30px;
`;

export const ButtonBurgerNavigationStyled = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  background: none;
  margin-right: 20px;
  border: none;
  &:hover {
    background: rgb(24, 24, 31);
  }
`
