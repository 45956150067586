import { IconBurgerNavigation, LogoMini } from '../icons';
import React from 'react';
import { useResize } from '../../../hooks/useResize';
import {
  ButtonBurgerNavigationStyled,
  HeaderInnerStyled,
  LinkItemHeaderStyled,
  LinksHeaderStyled,
  WrapperLanguageSelectStyled,
  WrapperLogoMiniStyled,
} from './styles';
import { useTranslation } from 'react-i18next';
const LanguageSelect = React.lazy(() => import('./../../tools/layouts/components/header/components/language-select/index'));


const Header = ({toggleCollapsed}: {toggleCollapsed: () => void}) => {
  const  { isScreen767 } = useResize();
  const { t } = useTranslation();

  return (
    <HeaderInnerStyled>
      {!isScreen767
        ? <WrapperLogoMiniStyled><LogoMini /></WrapperLogoMiniStyled>
        : <LinksHeaderStyled>
          {/*<LinkItemHeaderStyled>{t('authorizationForms.exchange')}</LinkItemHeaderStyled>*/}
          {/*<LinkItemHeaderStyled>{t('authorizationForms.nftShop')}</LinkItemHeaderStyled>*/}
          {/*<LinkItemHeaderStyled>{t('authorizationForms.academy')}</LinkItemHeaderStyled>*/}
        </LinksHeaderStyled>
      }
      {isScreen767 ?
        <WrapperLanguageSelectStyled>
          <LanguageSelect />
        </WrapperLanguageSelectStyled>
        : <>
          {/*<ButtonBurgerNavigationStyled*/}
          {/*  onClick={(event) => {*/}
          {/*    event.stopPropagation()*/}
          {/*    toggleCollapsed()*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <span role="img" aria-label="menu" color="white">*/}
          {/*    <IconBurgerNavigation/>*/}
          {/*  </span>*/}
          {/*</ButtonBurgerNavigationStyled>*/}
        </>
      }
    </HeaderInnerStyled>
  );
};

export { Header };
