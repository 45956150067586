import { CSSProperties, FC } from 'react';

interface IStyled {
  style?: CSSProperties | undefined;
}

export const CloseIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.96967 0.96967C1.26256 0.676777 1.73744 0.676777 2.03033 0.96967L6 4.93934L9.96967 0.96967C10.2626 0.676777 10.7374 0.676777 11.0303 0.96967C11.3232 1.26256 11.3232 1.73744 11.0303 2.03033L7.06066 6L11.0303 9.96967C11.3232 10.2626 11.3232 10.7374 11.0303 11.0303C10.7374 11.3232 10.2626 11.3232 9.96967 11.0303L6 7.06066L2.03033 11.0303C1.73744 11.3232 1.26256 11.3232 0.96967 11.0303C0.676777 10.7374 0.676777 10.2626 0.96967 9.96967L4.93934 6L0.96967 2.03033C0.676777 1.73744 0.676777 1.26256 0.96967 0.96967Z"
        fill="#F3F3F8"
      />
    </svg>
  );
};

export const DoorArrow = () => {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.33301 2.66667L5.39967 3.6L7.13301 5.33333H0.333008V6.66667H7.13301L5.39967 8.4L6.33301 9.33333L9.66634 6L6.33301 2.66667ZM12.333 10.6667H6.99967V12H12.333C13.0663 12 13.6663 11.4 13.6663 10.6667V1.33333C13.6663 0.6 13.0663 0 12.333 0H6.99967V1.33333H12.333V10.6667Z"
        fill="#FBFBFF"
      />
    </svg>
  );
};

export const Checked = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="4" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 0C0.895431 0 0 0.895431 0 2V14C0 15.1046 0.895431 16 2 16H14C15.1046 16 16 15.1046 16 14V2C16 0.895431 15.1046 0 14 0H2ZM12.1338 5.71967C11.8409 5.42678 11.3661 5.42678 11.0732 5.71967C11.0661 5.72674 11.0595 5.73424 11.0533 5.7421L7.58091 10.1667L5.48739 8.07322C5.1945 7.78033 4.71962 7.78033 4.42673 8.07322C4.13384 8.36612 4.13384 8.84099 4.42673 9.13388L7.07318 11.7803C7.36607 12.0732 7.84095 12.0732 8.13384 11.7803C8.14036 11.7738 8.14652 11.7669 8.15228 11.7597L12.1445 6.76947C12.4267 6.47582 12.4231 6.00897 12.1338 5.71967Z"
        fill="url(#paint0_linear_1274_47582)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1274_47582"
          x1="4.35956e-07"
          y1="16"
          x2="18.0967"
          y2="13.1045"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#088473" />
          <stop offset="1" stopColor="#12BFA8" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export const NoChecked = () => {
  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.646447 0.646447C0.841709 0.451184 1.15829 0.451184 1.35355 0.646447L4 3.29289L6.64645 0.646447C6.84171 0.451184 7.15829 0.451184 7.35355 0.646447C7.54882 0.841709 7.54882 1.15829 7.35355 1.35355L4.70711 4L7.35355 6.64645C7.54882 6.84171 7.54882 7.15829 7.35355 7.35355C7.15829 7.54882 6.84171 7.54882 6.64645 7.35355L4 4.70711L1.35355 7.35355C1.15829 7.54882 0.841709 7.54882 0.646447 7.35355C0.451184 7.15829 0.451184 6.84171 0.646447 6.64645L3.29289 4L0.646447 1.35355C0.451184 1.15829 0.451184 0.841709 0.646447 0.646447Z"
        fill="#6B6E75"
      />
    </svg>
  );
};

export const MailIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 4.5V4C0.5 3.17157 1.17157 2.5 2 2.5H14C14.8284 2.5 15.5 3.17157 15.5 4V4.5M0.5 4.5L6.72702 8.23621M0.5 4.5V12M15.5 4.5L9.27298 8.23621M15.5 4.5V12M9.27298 8.23621L8 9L6.72702 8.23621M9.27298 8.23621L15.5 12M15.5 12V12C15.5 12.8284 14.8284 13.5 14 13.5H2C1.17157 13.5 0.5 12.8284 0.5 12V12M6.72702 8.23621L0.5 12"
        stroke="#FBFBFF"
      />
    </svg>
  );
};

export const CheckMarkIcon: FC<IStyled> = ({ style }) => {
  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.07315 0.71967C7.36604 0.426777 7.84091 0.426777 8.13381 0.71967C8.42311 1.00897 8.42666 1.47582 8.14445 1.76947L4.15225 6.75973C4.14649 6.76693 4.14033 6.77381 4.13381 6.78033C3.84092 7.07322 3.36604 7.07322 3.07315 6.78033L0.426701 4.13388C0.133808 3.84099 0.133808 3.36612 0.426701 3.07322C0.719594 2.78033 1.19447 2.78033 1.48736 3.07322L3.58088 5.16674L7.05326 0.742105C7.05943 0.734235 7.06607 0.726743 7.07315 0.71967Z"
        fill="#58BF92"
      />
    </svg>
  );
};

export const CheckCircleIcon: FC<IStyled> = ({ style }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#58BF92"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M16.4554 7.4545C16.8948 7.01517 17.6071 7.01517 18.0464 7.4545C18.4804 7.88846 18.4857 8.58872 18.0624 9.02921L12.0741 16.5146C12.0654 16.5254 12.0562 16.5357 12.0464 16.5455C11.6071 16.9848 10.8948 16.9848 10.4554 16.5455L6.48576 12.5758C6.04641 12.1365 6.04642 11.4242 6.48575 10.9848C6.92509 10.5455 7.63741 10.5455 8.07675 10.9848L11.217 14.1251L16.4256 7.48816C16.4349 7.47635 16.4448 7.46511 16.4554 7.4545Z" fill="#58BF92"/>
    </svg>

  );
};

export const CrossMarkIcon: FC<IStyled> = ({ style }) => {
  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.646447 0.646447C0.841709 0.451184 1.15829 0.451184 1.35355 0.646447L4 3.29289L6.64645 0.646447C6.84171 0.451184 7.15829 0.451184 7.35355 0.646447C7.54882 0.841709 7.54882 1.15829 7.35355 1.35355L4.70711 4L7.35355 6.64645C7.54882 6.84171 7.54882 7.15829 7.35355 7.35355C7.15829 7.54882 6.84171 7.54882 6.64645 7.35355L4 4.70711L1.35355 7.35355C1.15829 7.54882 0.841709 7.54882 0.646447 7.35355C0.451184 7.15829 0.451184 6.84171 0.646447 6.64645L3.29289 4L0.646447 1.35355C0.451184 1.15829 0.451184 0.841709 0.646447 0.646447Z"
        fill="#F16063"
      />
    </svg>
  );
};

export const CrossTagIcon = () => {
  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.646447 0.646447C0.841709 0.451184 1.15829 0.451184 1.35355 0.646447L4 3.29289L6.64645 0.646447C6.84171 0.451184 7.15829 0.451184 7.35355 0.646447C7.54882 0.841709 7.54882 1.15829 7.35355 1.35355L4.70711 4L7.35355 6.64645C7.54882 6.84171 7.54882 7.15829 7.35355 7.35355C7.15829 7.54882 6.84171 7.54882 6.64645 7.35355L4 4.70711L1.35355 7.35355C1.15829 7.54882 0.841709 7.54882 0.646447 7.35355C0.451184 7.15829 0.451184 6.84171 0.646447 6.64645L3.29289 4L0.646447 1.35355C0.451184 1.15829 0.451184 0.841709 0.646447 0.646447Z"
        fill="#FBFBFF"
      />
    </svg>
  );
};

export const CheckTagIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0692 5.71967C11.3621 5.42678 11.837 5.42678 12.1299 5.71967C12.4192 6.00897 12.4228 6.47582 12.1405 6.76947L8.14834 11.7597C8.14258 11.7669 8.13642 11.7738 8.1299 11.7803C7.83701 12.0732 7.36214 12.0732 7.06924 11.7803L4.4228 9.13388C4.1299 8.84099 4.1299 8.36612 4.42279 8.07322C4.71569 7.78033 5.19056 7.78033 5.48346 8.07322L7.57698 10.1667L11.0493 5.7421C11.0555 5.73424 11.0622 5.72674 11.0692 5.71967Z"
        fill="#FBFBFF"
      />
    </svg>
  );
};

export const RedCrossSafetyCardIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.64645 4.64645C4.84171 4.45118 5.15829 4.45118 5.35355 4.64645L8 7.29289L10.6464 4.64645C10.8417 4.45118 11.1583 4.45118 11.3536 4.64645C11.5488 4.84171 11.5488 5.15829 11.3536 5.35355L8.70711 8L11.3536 10.6464C11.5488 10.8417 11.5488 11.1583 11.3536 11.3536C11.1583 11.5488 10.8417 11.5488 10.6464 11.3536L8 8.70711L5.35355 11.3536C5.15829 11.5488 4.84171 11.5488 4.64645 11.3536C4.45118 11.1583 4.45118 10.8417 4.64645 10.6464L7.29289 8L4.64645 5.35355C4.45118 5.15829 4.45118 4.84171 4.64645 4.64645Z"
        fill="#F16063"
      />
    </svg>
  );
};

export const GreenCheckSafetyCardIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0692 5.71967C11.3621 5.42678 11.837 5.42678 12.1299 5.71967C12.4192 6.00897 12.4228 6.47582 12.1405 6.76947L8.14834 11.7597C8.14258 11.7669 8.13642 11.7738 8.1299 11.7803C7.83701 12.0732 7.36214 12.0732 7.06924 11.7803L4.4228 9.13388C4.1299 8.84099 4.1299 8.36612 4.42279 8.07322C4.71569 7.78033 5.19056 7.78033 5.48346 8.07322L7.57698 10.1667L11.0493 5.7421C11.0555 5.73424 11.0622 5.72674 11.0692 5.71967Z"
        fill="#58BF92"
      />
    </svg>
  );
};

export const DeviceIcon: FC<IStyled> = ({ style }) => {
  return (
    <div style={style}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1977_21499)">
          <path
            d="M2.66667 3.99935H14.6667V2.66602H2.66667C1.93333 2.66602 1.33333 3.26602 1.33333 3.99935V11.3327H0V13.3327H9.33333V11.3327H2.66667V3.99935ZM15.3333 5.33268H11.3333C10.9667 5.33268 10.6667 5.63268 10.6667 5.99935V12.666C10.6667 13.0327 10.9667 13.3327 11.3333 13.3327H15.3333C15.7 13.3327 16 13.0327 16 12.666V5.99935C16 5.63268 15.7 5.33268 15.3333 5.33268ZM14.6667 11.3327H12V6.66602H14.6667V11.3327Z"
            fill="#FBFBFF"
          />
        </g>
        <defs>
          <clipPath id="clip0_1977_21499">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export const DefaultDeviceCloseIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.96967 0.96967C1.26256 0.676777 1.73744 0.676777 2.03033 0.96967L6 4.93934L9.96967 0.96967C10.2626 0.676777 10.7374 0.676777 11.0303 0.96967C11.3232 1.26256 11.3232 1.73744 11.0303 2.03033L7.06066 6L11.0303 9.96967C11.3232 10.2626 11.3232 10.7374 11.0303 11.0303C10.7374 11.3232 10.2626 11.3232 9.96967 11.0303L6 7.06066L2.03033 11.0303C1.73744 11.3232 1.26256 11.3232 0.96967 11.0303C0.676777 10.7374 0.676777 10.2626 0.96967 9.96967L4.93934 6L0.96967 2.03033C0.676777 1.73744 0.676777 1.26256 0.96967 0.96967Z"
        fill="#C3C3CE"
      />
    </svg>
  );
};
export const HoverDeviceCloseIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.96967 0.96967C1.26256 0.676777 1.73744 0.676777 2.03033 0.96967L6 4.93934L9.96967 0.96967C10.2626 0.676777 10.7374 0.676777 11.0303 0.96967C11.3232 1.26256 11.3232 1.73744 11.0303 2.03033L7.06066 6L11.0303 9.96967C11.3232 10.2626 11.3232 10.7374 11.0303 11.0303C10.7374 11.3232 10.2626 11.3232 9.96967 11.0303L6 7.06066L2.03033 11.0303C1.73744 11.3232 1.26256 11.3232 0.96967 11.0303C0.676777 10.7374 0.676777 10.2626 0.96967 9.96967L4.93934 6L0.96967 2.03033C0.676777 1.73744 0.676777 1.26256 0.96967 0.96967Z"
        fill="#FBFBFF"
      />
    </svg>
  );
};

export const StepperFinishedStepIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 16C0.5 7.43959 7.43959 0.5 16 0.5C24.5604 0.5 31.5 7.43959 31.5 16C31.5 24.5604 24.5604 31.5 16 31.5C7.43959 31.5 0.5 24.5604 0.5 16Z"
        stroke="url(#paint0_linear_1073_77111)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.39 9.93934C20.9107 9.35355 21.7549 9.35355 22.2756 9.93934C22.79 10.5179 22.7963 11.4516 22.2946 12.0389L15.1973 22.0195C15.1871 22.0339 15.1761 22.0476 15.1645 22.0607C14.6438 22.6464 13.7996 22.6464 13.2789 22.0607L8.57412 16.7678C8.05342 16.182 8.05342 15.2322 8.57412 14.6464C9.09482 14.0607 9.93904 14.0607 10.4597 14.6464L14.1815 18.8335L20.3547 9.98421C20.3656 9.96847 20.3774 9.95349 20.39 9.93934Z"
        fill="url(#paint1_linear_1073_77111)"
      />
      <defs>
        <linearGradient
          id="paint1_linear_1073_77111"
          x1="8.18359"
          y1="22.5"
          x2="24.4661"
          y2="19.5977"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#088473" />
          <stop offset="1" stopColor="#12BFA8" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const StepperProgressIcon = () => {
  return (
    <svg
      viewBox="0 0 1024 1024"
      focusable="false"
      data-icon="loading"
      width="40px"
      height="40px"
      fill="none"
      aria-hidden="true"
    >
      <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
      <defs>
        <linearGradient
          id="gradient"
          x1="-6"
          y1="48"
          x2="44.7176"
          y2="41.4852"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#088473" />
          <stop offset="1" stopColor="#12BFA8" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const AppleIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_666_55350)">
        <path
          d="M20.6033 6.46465C20.3779 7.15643 20.0132 7.79758 19.5736 8.33084V8.33182C19.1301 8.86813 18.5376 9.34231 17.8785 9.6567C17.2737 9.94524 16.6106 10.1006 15.9508 10.0492L15.7488 10.0335L15.7223 9.83239C15.6365 9.18397 15.7355 8.52755 15.944 7.92009C16.1866 7.2132 16.5796 6.56821 17.0013 6.07947L17.0012 6.0794C17.4375 5.56824 18.0227 5.1235 18.6494 4.80126C19.2782 4.47791 19.9536 4.27572 20.5682 4.25068L20.8006 4.24121L20.826 4.47333C20.9005 5.15221 20.8099 5.83034 20.6033 6.46465Z"
          fill="#FBFBFF"
        />
        <path
          d="M24.8755 12.5635C24.6527 12.7013 22.4998 14.0335 22.5265 16.7188C22.5546 19.956 25.2995 21.0782 25.451 21.1401H25.452L25.4585 21.1428L25.6739 21.232L25.6001 21.4524C25.5958 21.4654 25.6007 21.4515 25.5944 21.472C25.5243 21.6964 25.0495 23.2175 23.977 24.7848C23.4903 25.4957 22.9944 26.2055 22.409 26.7531C21.8028 27.32 21.1105 27.7027 20.2553 27.7185C19.4492 27.7337 18.9215 27.5053 18.3729 27.268C17.8498 27.0416 17.3058 26.8062 16.4545 26.8062C15.5602 26.8062 14.9885 27.0497 14.4379 27.2843C13.9196 27.5052 13.4183 27.7187 12.6961 27.7475H12.6951C11.8612 27.7785 11.1381 27.372 10.4955 26.7699C9.88065 26.1939 9.34197 25.4396 8.8511 24.7296C7.74302 23.1296 6.77935 20.8016 6.48161 18.4344C6.23672 16.4873 6.44142 14.5093 7.38279 12.8758C7.90831 11.9618 8.64279 11.2159 9.50151 10.6954C10.3563 10.1773 11.3343 9.8825 12.3523 9.86723V9.86722C13.2436 9.85105 14.0839 10.1845 14.8192 10.4764C15.3397 10.6829 15.8033 10.8669 16.1509 10.8669C16.458 10.8669 16.9106 10.6884 17.4383 10.4804C18.3295 10.129 19.4198 9.69919 20.5727 9.81383C21.0568 9.83488 22.0653 9.95085 23.0961 10.5095C23.7528 10.8654 24.4189 11.3997 24.9665 12.2011L25.1108 12.4124L24.8952 12.5517C24.8836 12.5591 24.8948 12.5515 24.8755 12.5635Z"
          fill="#FBFBFF"
        />
      </g>
      <defs>
        <clipPath id="clip0_666_55350">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const AndroidIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33594 24.0332C1.51372 21.6776 2.23594 19.4943 3.5026 17.4832C4.76927 15.4721 6.44705 13.8776 8.53594 12.6998L6.03594 8.39984C5.96927 8.19984 5.96371 7.99984 6.01927 7.79984C6.07483 7.59984 6.19149 7.44428 6.36927 7.33317C6.56927 7.22206 6.78594 7.19984 7.01927 7.2665C7.2526 7.33317 7.42483 7.4665 7.53594 7.6665L10.0026 11.8998C11.9137 11.0776 13.9137 10.6665 16.0026 10.6665C18.0915 10.6665 20.0915 11.0776 22.0026 11.8998L24.5026 7.6665C24.6137 7.4665 24.7859 7.33317 25.0193 7.2665C25.2526 7.19984 25.4693 7.22206 25.6693 7.33317C25.847 7.44428 25.9748 7.60539 26.0526 7.8165C26.1304 8.02762 26.1137 8.22206 26.0026 8.39984L23.4693 12.6998C25.5582 13.8776 27.2359 15.4721 28.5026 17.4832C29.7693 19.4943 30.4915 21.6776 30.6693 24.0332H1.33594ZM9.33594 20.3665C9.78038 20.3665 10.1693 20.1998 10.5026 19.8665C10.8359 19.5332 11.0026 19.1443 11.0026 18.6998C11.0026 18.2554 10.8359 17.8665 10.5026 17.5332C10.1693 17.1998 9.78038 17.0332 9.33594 17.0332C8.89149 17.0332 8.5026 17.1998 8.16927 17.5332C7.83594 17.8665 7.66927 18.2554 7.66927 18.6998C7.66927 19.1443 7.83594 19.5332 8.16927 19.8665C8.5026 20.1998 8.89149 20.3665 9.33594 20.3665ZM22.6693 20.3665C23.1137 20.3665 23.5026 20.1998 23.8359 19.8665C24.1693 19.5332 24.3359 19.1443 24.3359 18.6998C24.3359 18.2554 24.1693 17.8665 23.8359 17.5332C23.5026 17.1998 23.1137 17.0332 22.6693 17.0332C22.2248 17.0332 21.8359 17.1998 21.5026 17.5332C21.1693 17.8665 21.0026 18.2554 21.0026 18.6998C21.0026 19.1443 21.1693 19.5332 21.5026 19.8665C21.8359 20.1998 22.2248 20.3665 22.6693 20.3665Z"
        fill="#FBFBFF"
      />
    </svg>
  );
};

export const CopyIcon = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1989_47264)">
        <path
          d="M10.4987 0.666992H3.16536C2.43203 0.666992 1.83203 1.26699 1.83203 2.00033V10.667C1.83203 11.0337 2.13203 11.3337 2.4987 11.3337C2.86536 11.3337 3.16536 11.0337 3.16536 10.667V2.66699C3.16536 2.30033 3.46536 2.00033 3.83203 2.00033H10.4987C10.8654 2.00033 11.1654 1.70033 11.1654 1.33366C11.1654 0.966992 10.8654 0.666992 10.4987 0.666992ZM13.1654 3.33366H5.83203C5.0987 3.33366 4.4987 3.93366 4.4987 4.66699V14.0003C4.4987 14.7337 5.0987 15.3337 5.83203 15.3337H13.1654C13.8987 15.3337 14.4987 14.7337 14.4987 14.0003V4.66699C14.4987 3.93366 13.8987 3.33366 13.1654 3.33366ZM12.4987 14.0003H6.4987C6.13203 14.0003 5.83203 13.7003 5.83203 13.3337V5.33366C5.83203 4.96699 6.13203 4.66699 6.4987 4.66699H12.4987C12.8654 4.66699 13.1654 4.96699 13.1654 5.33366V13.3337C13.1654 13.7003 12.8654 14.0003 12.4987 14.0003Z"
          fill="#FBFBFF"
        />
      </g>
      <defs>
        <clipPath id="clip0_1989_47264">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const CardExitIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.96967 0.96967C1.26256 0.676777 1.73744 0.676777 2.03033 0.96967L6 4.93934L9.96967 0.96967C10.2626 0.676777 10.7374 0.676777 11.0303 0.96967C11.3232 1.26256 11.3232 1.73744 11.0303 2.03033L7.06066 6L11.0303 9.96967C11.3232 10.2626 11.3232 10.7374 11.0303 11.0303C10.7374 11.3232 10.2626 11.3232 9.96967 11.0303L6 7.06066L2.03033 11.0303C1.73744 11.3232 1.26256 11.3232 0.96967 11.0303C0.676777 10.7374 0.676777 10.2626 0.96967 9.96967L4.93934 6L0.96967 2.03033C0.676777 1.73744 0.676777 1.26256 0.96967 0.96967Z"
        fill="#C3C3CE"
      />
    </svg>
  );
};

export const CardExitIconHover = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.96967 0.96967C1.26256 0.676777 1.73744 0.676777 2.03033 0.96967L6 4.93934L9.96967 0.96967C10.2626 0.676777 10.7374 0.676777 11.0303 0.96967C11.3232 1.26256 11.3232 1.73744 11.0303 2.03033L7.06066 6L11.0303 9.96967C11.3232 10.2626 11.3232 10.7374 11.0303 11.0303C10.7374 11.3232 10.2626 11.3232 9.96967 11.0303L6 7.06066L2.03033 11.0303C1.73744 11.3232 1.26256 11.3232 0.96967 11.0303C0.676777 10.7374 0.676777 10.2626 0.96967 9.96967L4.93934 6L0.96967 2.03033C0.676777 1.73744 0.676777 1.26256 0.96967 0.96967Z"
        fill="#FBFBFF"
      />
    </svg>
  );
};

export const ExclamationSquare = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="4" fill="#14141F" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 0C0.895431 0 0 0.895431 0 2V14C0 15.1046 0.895431 16 2 16H14C15.1046 16 16 15.1046 16 14V2C16 0.895431 15.1046 0 14 0H2ZM8 4C7.46459 4 7.04623 4.46229 7.0995 4.99504L7.45025 8.50248C7.47849 8.78492 7.71616 9 8 9C8.28384 9 8.52151 8.78492 8.54975 8.50248L8.9005 4.99504C8.95377 4.46228 8.53541 4 8 4ZM8.00154 10C7.44926 10 7.00154 10.4477 7.00154 11C7.00154 11.5523 7.44926 12 8.00154 12C8.55383 12 9.00154 11.5523 9.00154 11C9.00154 10.4477 8.55383 10 8.00154 10Z"
        fill="#E8E264"
      />
    </svg>
  );
};
