import React from 'react';
import { Dropdown, MenuProps } from 'antd';
import Icon from '@ant-design/icons';
import './styles.css';
import { ReactComponent as MoreIcon } from '../../../../../assets/img/icons/more-icon.svg';
import { ReactComponent as Change } from '../../../../../assets/img/icons/change-icon.svg';
import { ReactComponent as Disconnect } from '../../../../../assets/img/icons/disconnect-icon.svg';
import { useTranslation } from 'react-i18next';

interface ICardDropdownProps {
  isExtendedMenu?: boolean;
  menuEditLink?: string;
  menuDisableLink?: string;
}

const CardDropdown = ({
  isExtendedMenu,
  menuEditLink,
  menuDisableLink,
}: ICardDropdownProps): JSX.Element => {

  const { t } = useTranslation();

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <a href={menuEditLink}>{t("buttons.change")}</a>,
      icon: <Icon component={Change} style={{ fontSize: '12px' }} />,
    },
    isExtendedMenu === true
      ? {
          key: '2',
        label: <a href={menuDisableLink}>{t("buttons.disconnect")}</a>,
          icon: <Icon component={Disconnect} style={{ fontSize: '12px' }} />,
        }
      : null,
  ];

  return (
    <>
      <Dropdown
        menu={{
          items,
          selectable: true,
        }}
        placement="bottomLeft"
        overlayClassName={'card-dropdown-secret'}
        overlayStyle={{
          borderRadius: 0,
        }}
      >
        <div
          style={{
            display: 'flex',
            border: 'none',
            padding: 0,
            height: '18px',
            position: 'relative',
            left: '7px',
          }}
        >
          <Icon component={MoreIcon} style={{ fontSize: '18px' }} />
        </div>
      </Dropdown>
    </>
  );
};

export { CardDropdown };
