import { CardProps, Typography } from 'antd';
import { SETTINGS_CARD_COMPONENTS as Components } from './_styles';
import { BasicTag } from '../../tags/basic-tag';
import { CardDropdown } from '../components/dropdown-menu';
import { SettingsTag } from '../../tags/settings-tag';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { t } from 'i18next';

interface ICardProps extends CardProps {
  cardTitle: string;
  cardText: string | JSX.Element;
  isMenu: boolean; // флаг для отображения меню у карточки
  isConnectedTag: boolean; // флаг для отображения тега подключения
  isTagWithoutIcon: boolean; // флаг для отображения тега без иконки
  isConnectTagWithoutIcon?: boolean; // флаг для отображения тега
  isExtendedMenu?: boolean; // флаг для отображения выпадающего меню с двумя пунктами или одним
  menuEditLink?: string; // ссылка для пункта изменение
  menuDisableLink?: string; // ссылка для пункта отключение
  statusTagTitle?: string;
  isConnect?: boolean;
  navLink?: string;
  onClick?: () => void;
  showEditModalSecret?: boolean;
  setShowEditModalSecret?: React.Dispatch<React.SetStateAction<boolean>>;
  children?: JSX.Element;
}

const { Title, Text } = Typography;

const CustomSettingsCard = ({
  cardTitle,
  cardText,
  isMenu,
  isConnectedTag,
  isTagWithoutIcon,
  isExtendedMenu,
  statusTagTitle,
  isConnect,
  isConnectTagWithoutIcon,
  navLink,
  menuEditLink,
  menuDisableLink,
  onClick,
  showEditModalSecret,
  setShowEditModalSecret,
  children,
}: ICardProps): JSX.Element => {
  const navigate = useNavigate();

  const eventHandler = () => {
    navigate(`${navLink ? navLink : ''}`);
  };

  return (
    <div onClick={onClick ? onClick : eventHandler}>
      <Components.Shadow>
        <Components.CardWrapper>
          <Components.Card>
            <Components.TextBlock>
              <Components.CardHeader>
                <Title level={5} style={{ margin: 0, fontSize: 18, fontWeight: 700 }}>
                  {cardTitle}
                </Title>

                {isMenu ? (
                  <CardDropdown
                    menuEditLink={menuEditLink}
                    menuDisableLink={menuDisableLink}
                    isExtendedMenu={isExtendedMenu}
                    // @ts-ignore
                    showEditModalSecret={showEditModalSecret}
                    // @ts-ignore
                    setShowEditModalSecret={setShowEditModalSecret}
                  />
                ) : null}
              </Components.CardHeader>
              <Text>{cardText}</Text>
            </Components.TextBlock>
            {
              children && (
                <Components.Tags>
                  {isConnectTagWithoutIcon === true ? (
                    <SettingsTag
                      text={statusTagTitle}
                      isConnected={true}
                      noIcon={true}
                    >
                      {children}
                    </SettingsTag>
                  ) : null}

                  {isTagWithoutIcon ? (
                    <SettingsTag
                      text={statusTagTitle}
                      isConnected={true}
                      noIcon={true}
                    >
                      {children}
                    </SettingsTag>
                  ) : null}

                  {isConnectedTag ? (
                    isConnect === true ? (
                      <BasicTag text={t('statuses.connected')} isConnected={true} />
                    ) : (
                      <BasicTag
                        text={t('statuses.disconnected')}
                        isConnected={false}
                      />
                    )
                  ) : null}
                </Components.Tags>
              )
            }
          </Components.Card>
        </Components.CardWrapper>
      </Components.Shadow>
    </div>
  );
};

export { CustomSettingsCard };
