import React from 'react';
import { Tag } from 'antd';
import styled from 'styled-components';

interface IStatusTagProps {
  preText?: string;
  text?: string | JSX.Element;
  isConnected?: boolean;
  noIcon?: boolean;
  isActive?: boolean;
  children?: JSX.Element;
}

const SettingsTag = ({ isConnected, children }: IStatusTagProps): JSX.Element => {
  return (
    <SettingsTagStyled $isConnected={isConnected}>
      <SettingsTagBodyStyled>
        {children}
      </SettingsTagBodyStyled>
    </SettingsTagStyled>
  );
};

const SettingsTagStyled = styled(Tag)<{ $isConnected: boolean | undefined }>`
  background: ${props => (props.$isConnected ? '#474755' : '#474755')};
  display: flex;
  width: min-content;
  height: 32px;
  border: none;
  border-radius: 8px;
`;

const SettingsTagBodyStyled = styled.span`
  font-weight: 500;
  color: #FBFBFF;
  font-style: normal;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
`;

export { SettingsTag };
