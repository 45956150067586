export const passwordValidator = (value: string, setState: any) => {
  let validData = [];
  value.length >= 8 && value.length <= 32 && validData.push('lengthValid');
  value.match(/[A-Z]/g) !== null &&
    value.match(/[a-z]/g) !== null &&
    validData.push('registerValid');
  value.match(
    /^[A-Za-z0-9\(\)\"\«\»\|\.\,\:\;\?\!\*\+\%\-\<\>\@\[\]\{\}\_\{\}\$\#\~]+$/g
  ) !== null && validData.push('langValid');
  value.match(/\d/g) !== null && validData.push('numValid');
  value.match(
    /[\(\)\"\«\»\|\.\,\:\;\?\!\*\+\%\-\<\>\@\[\]\{\}\_\{\}\$\#\~]/g
  ) !== null && validData.push('symbolValid');
  setState(validData);
  if (!value || validData.length === 5) {
    return Promise.resolve();
  }
  return Promise.reject(new Error());
};
