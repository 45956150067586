import { StatusTag } from '../../../../components/ui/tags/status-tag';
import { DoorArrow } from '../../../../assets/img/icons/icons';
import React, { memo } from 'react';
import { ErrorBoundary } from '../../../../components/ErrorBoundary';
import { t } from 'i18next';
import { useAuthState } from '../../../../state/useAuthState';
import { useNavigate } from 'react-router-dom';
import { useCookiesCustom } from '../../../../hooks/useCookieCustom';

type TStatusTagLastLogin = {
  date: string
}

const StatusTagLastLogin = memo(({ date }: TStatusTagLastLogin) => {
  const {removeCookie} = useCookiesCustom();
  const logout = useAuthState((state) => state.logout);
  const navigate = useNavigate();

  return (
    <ErrorBoundary logout={logout} removeCookie={removeCookie} navigate={navigate}>
      <StatusTag
        preText={t('titles.lastLogin').toString()}
        isConnected={false}
        noIcon={true}
      >
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <span
                  style={{
                    padding: '0 10px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <DoorArrow />
                </span>
                {date}
              </span>
      </StatusTag>
    </ErrorBoundary>
  );

});

export default StatusTagLastLogin;
