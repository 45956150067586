import { Button, Form, Input, notification } from 'antd';
import { useLoginState } from '../../state/useLoginState';
import { useNavigate } from 'react-router-dom';
import {
  ErrorCodeStyled,
  FormBorderStyled,
  FormButtonCancelStyled,
  FormButtonStyled,
  FormFooterStyled,
  FormStyled,
  FormSubTitleStyled,
  FormTimeCodeStyled,
  FormTimeStyled,
  FormTitleStyled,
  NewCodeStyled,
} from '../../common/styles';
import { ROUTER_PATH } from '../../router/path';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import React, { Suspense, useEffect, useState } from 'react';
import { useResize } from '../../hooks/useResize';
import { useTranslation } from 'react-i18next';
import { useAuthState } from '../../state/useAuthState';
import { TG_SUPPORT } from '../../shared/constants';
import { IconNotificationSuccess } from '../../components/ui/icons';
import { CloseIcon } from '../../assets/img/icons/icons';
import { FullScreenLoader } from '../../components/full-screen-loader';
import { useCookiesCustom } from '../../hooks/useCookieCustom';

const LoginPage = () => {
  const url = window.location.href;
  const [form] = Form.useForm();
  const login = useLoginState((state) => state.login);
  const loginExternal = useLoginState((state) => state.loginExternal);
  const step = useLoginState((state) => state.step);
  const setStep = useLoginState((state) => state.setStep);
  const setPassword = useLoginState((state) => state.setPassword);
  const setEmail = useLoginState((state) => state.setEmail);
  const email = useLoginState((state) => state.email);
  const password = useLoginState((state) => state.password);
  const { isScreen767, isScreen991 } = useResize();
  const clientTime = useAuthState((state) => state.clientTime);
  const setTotp = useLoginState((state) => state.setTotp);
  const isEmailTotp = useLoginState((state) => state.isEmailTotp);
  const setIsEmailTotp = useLoginState((state) => state.setIsEmailTotp);
  const {cookie, setCookie, removeCookie} = useCookiesCustom();
  const logout = useAuthState((state) => state.logout);
  const validIncorrectCode = useLoginState((state) => state.validIncorrectCode);
  const setValidIncorrectCode = useLoginState((state) => state.setValidIncorrectCode);
  const isLoading = useLoginState((state) => state.isLoading);
  const [isShowNofication, setIsShowNofication] = useState(true);

  const navigate = useNavigate();

  const { t } = useTranslation();

  const [validCode, setValidCode] = useState(false);
  const [time, setTime] = useState(60);

  const navToRecoveryPassword = () =>
    navigate(ROUTER_PATH.FormsPages.RECOVER_PASSWORD);

  const handleError = () => {
    setValidIncorrectCode(true);
  };

  const navToRegister = () => navigate(ROUTER_PATH.FormsPages.REGISTER);
  const dropInvalidDataErrors = () => {
    form.setFields([
      {
        name: 'email',
        errors: [
          ...form
            .getFieldError('email')
            .filter(
              (err: string) =>
                err !== t('authorizationForms.invalidEmailPassword'),
            ),
        ],
      },
      {
        name: 'password',
        errors: [
          ...form.getFieldError('password').filter((err: string) => !!err),
        ],
      },
    ]);
  };

  function getRedirectUrl(url: string): string {
    const parsedUrl = new URL(url);
    return parsedUrl.searchParams.get('redirect_url') || localStorage.getItem('redirectUrl') || '';
  }

  function getToCreate(url: string): string {
    const parsedUrl = new URL(url);
    if (parsedUrl.searchParams.get('to_create')) {
      return 'true';
    }
    return 'false';
  }

  const handleSubmit = () => {
    form.validateFields().then(() => {
      if (!!localStorage.getItem('redirectUrl')) {
        loginExternal({ setCookie, navigate, removeCookie, cookie })
          .then()
          .catch((err) => {
            // if (
            //   err.data.error_description.includes(
            //     'Incorrect username or password or 2fa!',
            //   ) ||
            //   err.data.error.includes('invalid_grant_or_2fa')
            // ) {
            //   setStep(1);
            // }
            // if (
            //   err.data.error_description.includes(
            //     'Incorrect username or password or Email!',
            //   ) ||
            //   err.data.error.includes('invalid_grant_or_Email')
            // ) {
            //   setStep(1);
            //   setIsEmailTotp(true);
            // }
            if (
              err.data.error_description.includes('Invalid user credentials') ||
              err.message.includes('TokenException: [Keycloak Guard] Wrong number of segments')
            ) {
              form.setFields([
                {
                  name: 'email',
                  errors: [t('authorizationForms.invalidEmailPassword')],
                },
                {
                  name: 'password',
                  errors: [''],
                },
              ]);
            }
          });
      } else {
        login({ navigate, setCookie, removeCookie, cookie })
          .then()
          .catch((err) => {
            // if (
            //   err.data.error_description.includes(
            //     'Incorrect username or password or 2fa!',
            //   ) ||
            //   err.data.error.includes('invalid_grant_or_2fa')
            // ) {
            //   setStep(1);
            // }
            // if (
            //   err.data.error_description.includes(
            //     'Incorrect username or password or Email!',
            //   ) ||
            //   err.data.error.includes('invalid_grant_or_Email')
            // ) {
            //   setStep(1);
            //   setIsEmailTotp(true);
            // }

            if (err.data.error_description.includes('Invalid user credentials') || err.message.includes('TokenException: [Keycloak Guard] Wrong number of segments')) {
              form.setFields([
                {
                  name: 'email',
                  errors: [t('authorizationForms.invalidEmailPassword')],
                },
                {
                  name: 'password',
                  errors: [''],
                },
              ]);
            }
          });
      }
    });
  };

  const handleSubmitCode = () => {
    if (!!localStorage.getItem('redirectUrl')) {
      loginExternal({ setCookie, navigate, removeCookie, cookie })
        .then()
        .catch((err) => {
          if (
            err.data.error_description.includes(
              'Incorrect username or password or 2fa!',
            )
          ) {
            handleError();
          }
        });
    } else {
      login({ navigate, setCookie, removeCookie, cookie }).catch((err) => {
        if (
          err.data.error_description.includes(
            'Incorrect username or password or 2fa!',
          )
        ) {
          handleError();
        }
      });
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const redirectUrl = getRedirectUrl(url) || localStorage.getItem('redirectUrl') || '';
    const toCreate = getToCreate(url);

    if (handleOpenNotification()) {
      setTimeout(() => {
        notification.success({
          icon: <IconNotificationSuccess />,
          message: <span
            style={{
              fontSize: '16px',
              fontWeight: '700',
              color: '#FBFBFF',
            }}>{t('registerConfirmation.successMessage')}</span>,
          description: <span style={{ color: '#FBFBFF' }}>{t('registerConfirmation.successDesc')}</span>,
          duration: 2,
          style: { background: '#2B2B36' },
          closeIcon: <CloseIcon />,
        });
      }, 500);
    }

    if (!!redirectUrl && !redirectUrl?.includes('account.')) {
      localStorage.setItem('redirectUrl', redirectUrl);
    }
    localStorage.setItem('inviteToken', urlParams.get('inviteToken') || cookie.inviteToken !== 'undefined' ? cookie.inviteToken : '');
    localStorage.setItem('toCreate', toCreate);

    if (redirectUrl === '' || !redirectUrl) {
      if (!!cookie['oidc.user']) {
        // TODO: Добавлено для теста сократить время
        // Если нет редиректа, то закидывать в настройки
        console.log('login Перенаправление на', ROUTER_PATH.AuthRoutes.ACCOUNT_SETTINGS_INDEX);
        setTimeout(() => {
          navigate(ROUTER_PATH.AuthRoutes.ACCOUNT_SETTINGS_INDEX);
        }, 4000);
      }
    }

    const handlePopstate = () => {
      // Если пользователь нажимает на кнопку назад в браузере
      setStep(0);
    };

    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);

  let hideEmail = function() {
    return email?.replace(
      /(.{2})(.*)(?=@)/,
      function(gp1: string, gp2: string, gp3: string) {
        for (let i = 0; i < gp3.length; i++) {
          gp2 += '*';
        }
        return gp2;
      },
    );
  };

  const handleNewTime = () => {
    setTime(60);
    handleSubmit();
  };

  useEffect(() => {
    if (step === 1) {
      setTime((prev) => prev - 1);
    }
  }, [step, clientTime]);

  const handleOpenNotification = () => {
    return !!(localStorage.getItem('email')
      && localStorage.getItem('redirectUrl')
      && localStorage.getItem('redirectUrl')?.includes('finmsapp')
      && !localStorage.getItem('redirectUrl')?.includes('home')
      && localStorage.getItem('inviteToken') === 'undefined'
      && localStorage.getItem('toCreate')
      && !JSON.parse(localStorage.getItem('toCreate')!)
      && !!JSON.parse(localStorage.getItem('email_verified')!));
  };

  if (!!cookie.token && !!cookie['oidc.user'] && isLoading) {
    const redirectUrl = localStorage.getItem('redirectUrl') || '';

    if (isShowNofication) {
      setIsShowNofication(false);
      if (redirectUrl.includes('finmsapp')) {
        notification.info({
          style: { background: '#2B2B36' },
          duration: 5,
          message: <span style={{ color: '#FBFBFF' }}>{t('descriptions.redirectFinMS')}</span>,
        });
      }

      if (redirectUrl.includes('exchangemap')) {
        notification.info({
          style: { background: '#2B2B36' },
          duration: 5,
          message: <span style={{ color: '#FBFBFF' }}>{t('descriptions.redirectExchangeMap')}</span>,
        });
      }

      if ((!redirectUrl || redirectUrl === '') && !redirectUrl.includes('finmsapp') && !redirectUrl.includes('exchangemap')) {
        notification.success({
          style: { background: '#2B2B36' },
          duration: 5,
          message: <span style={{ color: '#FBFBFF' }}>{t('descriptions.loginSuccess')}</span>,
        });
      }

      if ((!!redirectUrl || redirectUrl !== '') && !redirectUrl.includes('finmsapp') && !redirectUrl.includes('settings') && !redirectUrl.includes('exchangemap')) {
        console.log('login redirectUrl check', redirectUrl);
        notification.info({
          style: { background: '#2B2B36' },
          duration: 5,
          message: <span style={{ color: '#FBFBFF' }}>{t('descriptions.redirectSpot')}</span>,
        });
      }
    }

    return <FullScreenLoader />;
  }

  return (
    <ErrorBoundary
      logout={logout}
      removeCookie={removeCookie}
      navigate={navigate}
    >
      {step === 0 && (
        <Suspense fallback={'Loading...'}>
          <FormBorderStyled
            $isScreen767={isScreen767}
            $isScreen991={isScreen991}
          >
            <FormTitleStyled>{t('authorizationForms.logIn')}</FormTitleStyled>
            <FormStyled
              layout={'vertical'}
              requiredMark={false}
              form={form}
              onChange={dropInvalidDataErrors}
            >
              <Form.Item
                label={t('authorizationForms.enterEmail')}
                name='email'
                rules={[
                  {
                    type: 'email',
                    message: `${t('authorizationForms.invalidEmailFormat')}`,
                  },
                  {
                    required: true,
                    message: `${t('authorizationForms.enterEmail')}`,
                  },
                ]}
                style={{ marginBottom: 24 }}
              >
                <Input
                  placeholder={t('authorizationForms.email').toString()}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    localStorage.setItem('loginEmail', e.target.value);
                  }}
                />
              </Form.Item>

              <Form.Item
                name='password'
                label={`${t('authorizationForms.enterPassword')}`}
                rules={[
                  {
                    required: true,
                    message: `${t('authorizationForms.enterPassword')}`,
                  },
                ]}
              >
                <Input.Password
                  placeholder={`${t('authorizationForms.password')}`}
                  onChange={(e) => setPassword(e.target.value)}
                  onPressEnter={handleSubmit}
                />
              </Form.Item>
              <Button type={'ghost'} onClick={navToRecoveryPassword}>
                {t('authorizationForms.forgotYourPassword')}
              </Button>
              <FormButtonStyled onClick={handleSubmit}>
                {t('authorizationForms.come')}
              </FormButtonStyled>
            </FormStyled>
            <FormFooterStyled $isScreen767={isScreen767}>
              <div>{t('authorizationForms.notAccount')}</div>
              <Button type={'ghost'} onClick={navToRegister}>
                {t('authorizationForms.createAccount')}
              </Button>
            </FormFooterStyled>
          </FormBorderStyled>
        </Suspense>
      )}
      {step === 1 && (
        <Suspense fallback={'Loading...'}>
          <FormBorderStyled
            $isScreen767={isScreen767}
            $isScreen991={isScreen991}
          >
            <FormTitleStyled>{t('authorizationForms.signIn')}</FormTitleStyled>
            {validCode &&
              <ErrorCodeStyled style={{ color: 'red' }}>{t('authorizationForms.emptyField')}</ErrorCodeStyled>}

            {validIncorrectCode && <ErrorCodeStyled
              style={{ color: 'red' }}>
              {t('authorizationForms.invalidTotpCode')}
            </ErrorCodeStyled>}
            {isEmailTotp ? (
              <FormSubTitleStyled>
                {t('authorizationForms.toEmailAddress')} {hideEmail()}{' '}
                {t('authorizationForms.codeHasBeenEntered')}
              </FormSubTitleStyled>
            ) : (
              <FormSubTitleStyled>
                {t('authorizationForms.enter2FA')}
              </FormSubTitleStyled>
            )}
            <FormStyled layout={'vertical'} requiredMark={false} form={form}>
              <Form.Item
                name='totp'
                label={t('authorizationForms.identificationCode')}
                rules={[
                  () => ({
                    validator(_, value) {
                      setValidCode(!value);
                    },
                  }),
                ]}
              >
                <Input
                  placeholder={'＿ ＿ ＿ ＿ ＿ ＿ ＿ ＿'}
                  onPressEnter={handleSubmitCode}
                  onChange={(e) => {
                    setTotp(e.target.value);
                    if (e.target.value) {
                      setValidIncorrectCode(false);
                    }
                  }}
                />
              </Form.Item>

              {isEmailTotp && (
                <FormTimeCodeStyled>
                  {time <= 0 ? (
                    <NewCodeStyled onClick={handleNewTime}>
                      {t('authorizationForms.submitNewCode')}{' '}
                    </NewCodeStyled>
                  ) : (
                    `${t('authorizationForms.submitNewCode')} `
                  )}
                  {time > 0 ? (
                    <>
                      {t('authorizationForms.through')}
                      <FormTimeStyled>
                        {time >= 60
                          ? '01:00'
                          : time >= 10
                            ? `00:${time}`
                            : `00:0${time}`}
                        {t('authorizationForms.seconds')}
                      </FormTimeStyled>
                    </>
                  ) : (
                    <></>
                  )}
                </FormTimeCodeStyled>
              )}
              <FormButtonStyled
                disabled={!form.getFieldValue('totp')}
                onClick={handleSubmitCode}
              >
                {t('authorizationForms.confirm')}
              </FormButtonStyled>
              <FormButtonCancelStyled onClick={() => {
                setStep(0);
                setValidIncorrectCode(false);
                form.setFieldValue('totp', '');
              }}>
                {t('authorizationForms.cancel')}
              </FormButtonCancelStyled>
            </FormStyled>
            <FormFooterStyled $isScreen767={isScreen767}>
              <div>{t('authorizationForms.problemsWithCode')}</div>
              <Button onClick={() => window.open(TG_SUPPORT)} type={'ghost'}>
                {t('authorizationForms.help')}
              </Button>
            </FormFooterStyled>
          </FormBorderStyled>
        </Suspense>
      )}
    </ErrorBoundary>
  );
};

export default LoginPage;
