import React from 'react';
import { Tag } from 'antd';
import Icon from '@ant-design/icons';
import { CheckTagIcon, CrossTagIcon } from '../../../../assets/img/icons/icons';
import styled from 'styled-components';

interface IStatusTagProps {
  preText?: string;
  text?: string | JSX.Element;
  isConnected?: boolean;
  noIcon?: boolean;
  isActive?: boolean;
  children?: JSX.Element;
  isMoratorium?: boolean;
}

const StatusTag = ({
                     preText,
                     isConnected,
                     noIcon,
                     children,
                     isMoratorium = false,
                   }: IStatusTagProps): JSX.Element => {
  return (
    <StatusTagStyled $noIcon={noIcon} $isConnected={isConnected} $isMoratorium={isMoratorium}
                     icon={
                       noIcon === true ? null : isConnected === true ? (
                         <Icon component={CheckTagIcon} style={{ fontSize: '16px' }} />
                       ) : (
                         <Icon component={CrossTagIcon} style={{ fontSize: '7px' }} />
                       )
                     }
    >
      <StatusTagPreTextStyled $isMoratorium={isMoratorium}>
        {preText}
      </StatusTagPreTextStyled>
      <StatusTagChildrenStyled $isMoratorium={isMoratorium}>
        {children}
      </StatusTagChildrenStyled>
    </StatusTagStyled>
  );
};

const StatusTagStyled = styled(Tag)<{
  $isConnected: boolean | undefined;
  $isMoratorium: boolean | undefined;
  $noIcon: boolean | undefined
}>`
  display: flex;
  align-items: center;
  width: min-content;
  height: 32px;
  border: ${props => (props.$isMoratorium ? '1px solid #E8E264' : 'none')};
  border-radius: 8px;
  box-shadow: ${props =>
    props.$isConnected
      ? '0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)'
      : 'none'};
  background: ${props =>
    props.$isConnected
      ? '#767687'
      : props.$isMoratorium
        ? '#14141F'
        : '#474755'};
  color: ${props =>
    props.$noIcon || props.$isConnected ? '#202124' : '#6B6E75'};
  font-weight: ${props => (props.$noIcon ? '400' : '600')};
  padding: 9px 16px;
  margin-inline-end: 0;
`;

const StatusTagPreTextStyled = styled.span<{ $isMoratorium: boolean | undefined }>`
  font-weight: 500;
  color: #C3C3CE;
    // color: ${props => (props.$isMoratorium ? '#E8E264' : '#C3C3CE')},
`;

const StatusTagChildrenStyled = styled.span<{ $isMoratorium: boolean | undefined }>`
  font-weight: 500;
  font-style: normal;
  font-size: 12px;
  line-height: 15px;
  color: ${props => (props.$isMoratorium ? '#E8E264' : '#FBFBFF')};,
`;

export { StatusTag };
