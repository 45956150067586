import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { API_URL, EDENEX_ACCOUNT_URL } from '../packages/keycloak-client/constants';
import { produce } from 'immer';
import { axios } from '../shared/exios';

export interface IOtpGenerate {
  totpSecretQRCode: string,
  encodedTotpSecret: string,
}

type TCreateCredentialsState = {
  allCredentials: any[],
  otpCredentials: any[],
  otpGenerateData: IOtpGenerate | null
  otpIsConnected: boolean;
  deviceName: string;
  totpInitialCode: string;
  getCredentials: (accessToken: string) => Promise<void>;
  confirmCredentials: (accessToken: string) => Promise<void>;
  generateCredentials: (accessToken: string) => Promise<void>;
  setOtpCredentials: (value: any[]) => void;
  setAllCredentials: (value: any[]) => void;
  setOtpIsConnected: (value: boolean) => void;
  setDeviceName: (value: string) => void;
  setTotpInitialCode: (value: string) => void;
  deleteOtpCredential: (accessToken: string) => Promise<void>;
}
const API = API_URL.replace(`/server/api`, '');
export const useCredentialsState = create<TCreateCredentialsState>()(
  devtools(
    (set, get) => ({
      allCredentials: [],
      otpCredentials: [],
      deviceName: '',
      totpInitialCode: '',
      otpGenerateData: null,
      otpIsConnected: false,
      setOtpIsConnected: (value: boolean) => {
        set(
          produce((draft) => {
            draft.otpIsConnected = value;
          }), false, {
            type: 'useCredentialsState => setOtpIsConnected',
          },
        );
      },
      setOtpCredentials: (value: any[]) => {
        set(
          produce((draft) => {
            const otpArr = value?.filter((credential: any) => credential.type === 'otp');
            draft.otpCredentials = otpArr;
            draft.otpIsConnected = otpArr?.length > 0;
          }), false, {
            type: 'useCredentialsState => setOtpCredentials',
          },
        );
      },
      setAllCredentials: (value: any[]) => {
        set(
          produce((draft) => {
            draft.allCredentials = value;
          }), false, {
            type: 'useCredentialsState => setAllCredentials',
          },
        );
      },
      setDeviceName: (value: string) => {
        set(
          produce((draft) => {
            draft.deviceName = value;
          }), false, {
            type: 'useCredentialsState => setDeviceName',
          },
        );
      },
      setTotpInitialCode: (value: string) => {
        set(
          produce((draft) => {
            draft.totpInitialCode = value;
          }), false, {
            type: 'useCredentialsState => setTotpInitialCode',
          },
        );
      },
      getCredentials: async (accessToken: string) => {
        try {
          const res = await axios.get(`${EDENEX_ACCOUNT_URL}/edenex-account/api/credentials`);

          const { data } = res?.data;

          get().setAllCredentials(data);
          get().setOtpCredentials(data);
        } catch (e) {
          console.log('getCredentials error', e);
        }
      },
      generateCredentials: async (accessToken: string) => {
        try {
          const res = await axios.get(`${EDENEX_ACCOUNT_URL}/edenex-account/api/credentials-generate`, {
            headers: {
              Authorization: axios.defaults.headers['Authorization'] || `Bearer ${accessToken?.replace('Bearer ', '')}`,
            },
          });

          const { data } = res?.data;

          set(
            produce((draft) => {
              draft.otpGenerateData = data;
            }), false, {
              type: 'useCredentialsState => generateCredentials',
            },
          );

        } catch (e) {
          console.log('generateCredentials error', e);
        }
      },
      confirmCredentials: async (accessToken: string) => {
        try {
          await axios.post(`${EDENEX_ACCOUNT_URL}/edenex-account/api/credentials-confirm`, {
            deviceName: get().deviceName,
            totpInitialCode: get().totpInitialCode,
            encodedTotpSecret: get().otpGenerateData?.encodedTotpSecret,
          }, {
            headers: {
              Authorization: axios.defaults.headers['Authorization'] || `Bearer ${accessToken?.replace('Bearer ', '')}`,
            },
          });

          return Promise.resolve();
        } catch (e) {
          console.log('generateCredentials error', e);
          return Promise.reject(e);
        }
      },
      deleteOtpCredential: async (accessToken: string) => {
        try {
          await axios.post(`${EDENEX_ACCOUNT_URL}/edenex-account/api/credentials-delete`, {
            deviceName: get().deviceName,
            totpCode: get().totpInitialCode,
          }, {
            headers: {
              Authorization: axios.defaults.headers['Authorization'] || `Bearer ${accessToken?.replace('Bearer ', '')}`,
            },
          });

          return Promise.resolve();
        } catch (e) {
          console.log('generateCredentials error', e);
          return Promise.reject(e);
        }
      },
    }), {
      name: 'useCredentialsState',
      anonymousActionType: 'useCredentialsState action',
    },
  ),
);
