import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { produce } from 'immer';
import jwt from 'jwt-decode';
import { ACCOUNT_URL, EDENEX_ACCOUNT_URL } from '../packages/keycloak-client/constants';
import { useLoginState } from './useLoginState';
import { axios } from '../shared/exios';
import { default as baseAxios } from 'axios';
import { deleteCookieByName, getCookieByName, setCookieByName } from '../shared/helpers/controlCookies';
import jwtDecode from 'jwt-decode';

type TUserKC = {
  access_token: string | undefined;
  refresh_token: string | undefined;
  auth_time: number;
  email: string;
  email_verified: boolean;
  sub: string;
  profile: {
    iat: number;
  };
};

type TUserAPI = {
  account: {
    email: string;
    status: string;
    userId: number;
    uid: string;
    total: number;
    totalFiat: number;
    last_login: string;
  };
};

type TGetUserKC = {
  session: any;
  setCookie: any;
};

type TClientCardState = {
  isAuth: boolean;
  isLoading: boolean;
  serverTime: number;
  clientTime: number;
  userKC: TUserKC;
  userAPI: TUserAPI;
  logout: () => void;
  getUserKC: ({ session, setCookie }: TGetUserKC) => TUserKC | any;
  refreshToken: (config: any) => Promise<void>;
  getServerTime: (accessToken: string) => Promise<any>;
  setServerTime: (value: number) => void;
  setClientTime: (value: number) => void;
  getUserProfileFromAPI: () => Promise<any>;
  setIsAuth: (value: boolean) => void;
};

export type TDecodedToken = {
  acr: string;
  aud: string;
  azo: string;
  email: string;
  email_verified: boolean;
  exp: number;
  iat: number;
  iss: string;
  jti: string;
  preferred_username: string;
  scope: string;
  session_state: string;
  sid: string;
  sub: string;
  typ: string;
};

export const useAuthState = create<TClientCardState>()(
  devtools(
    (set, get) => ({
      isAuth: false,
      isLoading: true,
      serverTime: 0,
      clientTime: 0,
      userKC: {
        access_token: undefined,
        refresh_token: undefined,
        auth_time: 0,
        email: '',
        email_verified: false,
        sub: '',
        profile: {
          iat: 0,
        },
      },
      userAPI: {
        account: {
          email: '',
          status: 'active',
          userId: 0,
          uid: '',
          total: 0,
          totalFiat: 0,
          last_login: '',
        },
      },
      setIsAuth: (value: boolean) => {
        set(
          produce((draft) => {
            draft.isAuth = value;
          }),
        );
      },
      refreshToken: async (config) => {
        try {
          if (!getCookieByName('refresh_token')) return;
          const res = await baseAxios.post(
            `${ACCOUNT_URL}/server/edenex-account/api/refresh-token`,
            {
              refresh_token: getCookieByName('refresh_token')!.replace('Bearer ', '')
            },
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: ''
              }
            }
          );

          const { access_token, refresh_token } = res!.data?.data;
          const decodedToken: TDecodedToken = jwtDecode(access_token);

          if (config && refresh_token && access_token) {
            setCookieByName(
              'oidc.user',
              JSON.stringify({
                // @ts-ignore
                ...getCookieByName('oidc.user'),
                access_token: `Bearer ${access_token}`,
                refresh_token
              })
            );

            setCookieByName('email', decodedToken?.email);
            setCookieByName('uid', decodedToken?.sub);
            setCookieByName('token', access_token);
            setCookieByName('refresh_token', refresh_token);

            return await axios(config);
          }
        } catch (e) {
          console.error('refreshToken error', e);
          useAuthState.getState().logout();
        }
      },
      getServerTime: async (accessToken: string) => {
        try {
          const res = await axios.get(
            `${EDENEX_ACCOUNT_URL}/edenex-account/api/server-time`,
            {
              headers: {
                Authorization: `Bearer ${accessToken?.replace('Bearer ', '')}`,
              },
            },
          );
          const {
            data: { server_time },
          } = res.data;
          set(
            produce((draft) => {
              draft.setServerTime(server_time);
            }),
          );
        } catch (e) {
          console.error('getServerTime error', e);
        }
      },
      setServerTime: (value: number) => {
        set(
          produce((draft) => {
            draft.serverTime = value;
          }),
        );
      },
      getUserKC: ({ session, setCookie }: TGetUserKC) => {
        try {
          const userKC = session ? session : null;

          if (!userKC) {
            return null;
          }

          const decodedToken: TDecodedToken = jwt(userKC?.access_token || '');
          const AccessToken = `Bearer ${userKC?.access_token?.replace('Bearer ', '')}`

          setCookie('email', decodedToken?.email);
          setCookie('uid', decodedToken?.sub);
          setCookie('token', `${userKC?.access_token?.replace('Bearer ', '')}`);
          setCookie('refresh_token', `${userKC?.refresh_token}`);
          setCookie('email_verified', localStorage.getItem('email_verified'));
          setCookie('fromEdenex', true);

          get().getServerTime(AccessToken);
          get().getUserProfileFromAPI();

          set(
            produce((draft) => {
              draft.userKC = userKC;
            }),
          );

          return {
            ...userKC,
            access_token: userKC?.access_token,
            refresh_token: userKC?.refresh_token,
            email_verified: decodedToken?.email_verified,
            uid: decodedToken?.sub,
          };
        } catch (e) {
          console.error(e);
        }
      },

      setClientTime: (value: number) => {
        set(
          produce((draft) => {
            draft.clientTime += value;
          }),
        );
      },
      logout: async () => {
        useLoginState.getState().setStep(0);
        localStorage.clear();

        deleteCookieByName('token');
        deleteCookieByName('refresh_token');
        deleteCookieByName('uid');
        deleteCookieByName('email');
        deleteCookieByName('oidc.user');
        deleteCookieByName('remainingTime');
        deleteCookieByName('refreshTokenTimestamp');
        deleteCookieByName('currentServerTime');
        deleteCookieByName('inviteToken');
        deleteCookieByName('email_verified');
        deleteCookieByName('inviteLink');
        deleteCookieByName('fromEdenex');
        deleteCookieByName('fromFinms');
        deleteCookieByName('fromPortal');
        deleteCookieByName('currentPointId');

        set(
          produce((draft) => {
            draft.isAuth = false;
          }),
        );
      },

      getUserProfileFromAPI: async () => {
        const response = await axios.get(`${EDENEX_ACCOUNT_URL}/edenex-account/api/me`);
        if (!!response) {
          set(
            produce((draft) => {
              draft.userAPI.account = response.data;
            }),
            false,
            {
              type: 'useAuthStore => getUserProfileFromAPI',
            },
          );
        }
      },
    }),
    {
      anonymousActionType: 'useAuthState action',
      name: 'useClientStateV2',
    },
  ),
);
