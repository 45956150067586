import styled, { css } from "styled-components";


export const WrapperContentStyled = styled.div<{
  $isScreen1199?: boolean,
  $isScreen991?: boolean,
  $isScreen767?: boolean,
  $isScreen575?: boolean
}>`
  margin: 0 auto;
  max-width: 1160px;
  width: 100%;
  height: 100%;
  direction: ltr;
  ${({ $isScreen1199 }) =>
  !$isScreen1199 &&
  css`
      max-width: 960px;
    `}

  ${({ $isScreen991 }) =>
  !$isScreen991 &&
  css`
      max-width: 640px;
    `}

  ${({ $isScreen767 }) =>
  !$isScreen767 &&
  css`
      max-width: 575px;
    `}
  ${({ $isScreen575 }) =>
  !$isScreen575 &&
  css`
      max-width: 350px;
    `}
`
export const LinkStyled = styled.a`
  color: #5297FF;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const PrivacyPolicyPageStyled = styled.div<{
  $isScreen991?: boolean;
  $isScreen767?: boolean;
  $isScreen575?: boolean;
}>`
  margin: 7rem 0;
  padding: 0 14em;

  ${({ $isScreen991 }) =>
  !$isScreen991 &&
  css`
      padding: 0 3em;
    `}

  ${({ $isScreen767 }) =>
  !$isScreen767 &&
  css`
      margin: 4rem 0;
    `}

    ${({ $isScreen575 }) =>
  !$isScreen575 &&
  css`
      padding: 1em;
      margin: 2rem 0;
    `}
`;

export const TitleH1Styled = styled.h1<{
  $isScreen991?: boolean;
  $isScreen767?: boolean;
  $isScreen575?: boolean;
}>`
  margin-bottom: 4rem;
  font-family: "Inter", sans-serif;
  font-size: 36px;
  line-height: 62px;
  color: #FBFBFF;
  font-weight: bold;

  ${({ $isScreen991 }) =>
  !$isScreen991 &&
  css`
      margin-bottom: 3rem;
      font-size: 28px;
      line-height: 45px;
    `}

  ${({ $isScreen767 }) =>
  !$isScreen767 &&
  css`
      margin-bottom: 2rem;
      font-size: 22px;
      line-height: 30px;
    `}

    ${({ $isScreen575 }) =>
  !$isScreen575 &&
  css`
      margin-bottom: 1rem;
      font-size: 18px;
      line-height: 25px;
    `}
`;

export const PrivacyPolicyPageBlockStyled = styled.div<{
  $isScreen991?: boolean;
  $isScreen767?: boolean;
  $isScreen575?: boolean;
}>`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #898998;
  margin-bottom: 2rem;
  word-wrap: break-word;

  ${({ $isScreen991 }) =>
  !$isScreen991 &&
  css`
      font-size: 0.95rem;
      line-height: 1.1rem;
      margin-bottom: 1.5rem;
    `}

  ${({ $isScreen767 }) =>
  !$isScreen767 &&
  css`
      font-size: 0.8rem;
      line-height: 1rem;
      margin-bottom: 1.3rem;
    `}

  ${({ $isScreen575 }) =>
  !$isScreen575 &&
  css`
      font-size: 0.75rem;
      line-height: 0.9rem;
      margin-bottom: 1rem;
    `}
`;

export const PrivacyPolicyPageTitleStyled = styled.div<{
  $isScreen991?: boolean;
  $isScreen767?: boolean;
  $isScreen575?: boolean;
}>`
  font-family: "Inter", sans-serif;
  word-wrap: break-word;
  font-weight: 700;
  line-height: 25px;
  color: #FBFBFF;
  font-size: 16px;
  margin-bottom: 16px;

  ${({ $isScreen991 }) =>
  !$isScreen991 &&
  css`
      line-height: 22px;
      font-size: 15px;
    `}

  ${({ $isScreen767 }) =>
  !$isScreen767 &&
  css`
    line-height: 20px;
    font-size: 14px;
    `}

${({ $isScreen575 }) =>
  !$isScreen575 &&
  css`
    line-height: 18px;
    font-size: 13px;
    `}
`;
