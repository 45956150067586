import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { EDENEX_ACCOUNT_URL } from '../packages/keycloak-client/constants';
import { produce } from 'immer';
import { ROUTER_PATH } from '../router/path';
import { useAuthState } from './useAuthState';
import fb from '../packages/fb/fb';
import { axios } from '../shared/exios';

type TLoginState = {
  login: ({ navigate, setCookie, removeCookie, cookie }: TLoginProps) => Promise<void>,
  loginExternal: ({ setCookie, navigate, removeCookie, cookie }: TLoginExternal) => Promise<void>;
  setEmail: (value: string) => void;
  setPassword: (value: string) => void;
  reset: () => void;
  password: string;
  email: string;
  totp: string;
  token: string;
  getSessionByHashToken: ({ setCookie }: TGetSessionByHashToken) => Promise<void>;
  setHashToken: (value: string) => void;
  setTotp: (value: string) => void;
  step: number;
  setStep: (value: number) => void;
  isRedirect: boolean;
  setIsRedirect: (value: boolean) => void;
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
  isEmailTotp: boolean;
  setIsEmailTotp: (value: boolean) => void;
  validIncorrectCode: boolean;
  setValidIncorrectCode: (value: boolean) => void;
  isLogin: boolean;
  setIsLogin: (value: boolean) => void;
  loginError: string;
  setLoginError: (value: string) => void;
};

type TLoginData = {
  client_id: string;
  grant_type: string;
  username: string;
  password: string;
  totp?: string;
}

type TLoginProps = {
  navigate: any;
  setCookie: any;
  removeCookie: any;
  cookie: any;
}

type TGetSessionByHashToken = {
  setCookie: any;
}

type TLoginExternal = {
  navigate: any;
  setCookie: any;
  removeCookie: any;
  cookie: any;
}

export const useLoginState = create<TLoginState>()(
  devtools(
    (set, get) => ({
      password: '',
      email: localStorage.getItem('loginEmail') || '',
      totp: '',
      token: '',
      step: 0,
      isRedirect: false,
      isLoading: true,
      isEmailTotp: false,
      validIncorrectCode: false,
      isLogin: false,
      loginError: '',
      setLoginError: (error: string) => {
        set(
          produce((draft) => {
            draft.loginError = error;
          }),
        );
      },
      setIsLogin: (value: boolean) => {
        set(
          produce((draft) => {
            draft.isLogin = value;
          }),
        );
      },
      setValidIncorrectCode: (value: boolean) => {
        set(
          produce((draft) => {
            draft.validIncorrectCode = value;
          }),
        );
      },
      setIsEmailTotp: (value: boolean) => {
        set(
          produce((draft) => {
            draft.isEmailTotp = value;
          }),
        );
      },
      setIsLoading: (value: boolean) => {
        set(
          produce((draft) => {
            draft.isLoading = value;
          }),
        );
      },
      loginExternal: async ({ setCookie, navigate, removeCookie, cookie }: TLoginExternal) => {
        try {
          useLoginState.getState().setIsLoading(true);
          const data: TLoginData = {
            client_id: 'crypto.web',
            grant_type: 'password',
            username: useLoginState.getState().email,
            password: useLoginState.getState().password,
          };

          if (get().totp) {
            data.totp = get().totp;
          }

          const res = await axios.post(`${EDENEX_ACCOUNT_URL}/edenex-account/api/login`, data, {
            headers: {
              'Authorization': ``,
            },
          });
          const { success, data: { hash } } = res?.data;
          const redirectUrl = localStorage.getItem('redirectUrl') || '';
          const inviteToken = localStorage.getItem('inviteToken') || cookie.inviteToken;

          if (success) {
            set(
              produce((draft) => {
                draft.setIsRedirect(true);
                draft.setHashToken(hash);
                draft.getSessionByHashToken({ setCookie });
                draft.setIsLogin(true);
                draft.reset();
              }),
            );
            set(
              produce((draft) => {
                draft.isLoading = true;
              }),
            );
            setTimeout(() => {
              if (!!inviteToken && inviteToken !== 'undefined') {
                setTimeout(() => {
                  navigate(`${ROUTER_PATH.FormsPages.CONFIRM_INVITE}?inviteToken=${inviteToken}`);
                }, 4000);
              } else if (!!redirectUrl && redirectUrl !== 'undefined' && (!inviteToken || inviteToken === 'undefined')) {
                setTimeout(() => {
                  window.location.href = `${redirectUrl}`;
                }, 4000);
              }
            }, 1000);
          }
        } catch (e: any) {
          console.log('loginExternal error', e?.response?.data?.data?.error);
          set(
            produce((draft) => {
              draft.setLoginError(e?.response?.data?.data?.error);
            }),
          );
          return Promise.reject(e.response.data);
        }
      },
      setIsRedirect: (value: boolean) => {
        set(
          produce((draft) => {
            draft.isRedirect = value;
          }), false, {
            type: 'useLoginState => setIsRedirect',
          },
        );
      },
      login: async ({ navigate, setCookie, removeCookie, cookie }: TLoginProps) => {
        try {

          useLoginState.getState().setIsLoading(true);

          const data: TLoginData = {
            client_id: 'crypto.web',
            grant_type: 'password',
            username: useLoginState.getState().email,
            password: useLoginState.getState().password,
          };

          if (get().totp) {
            data.totp = get().totp;
          }

          // @ts-ignore
          const res = await axios.post(`${EDENEX_ACCOUNT_URL}/edenex-account/api/login`, data, {
            headers: {
              'Authorization': ``,
            },
          });
          const { success, data: { hash } } = res?.data;

          const redirectUrl = localStorage.getItem('redirectUrl') || '';
          const inviteToken = localStorage.getItem('inviteToken') || cookie.inviteToken;

          if (success) {
            set(
              produce((draft) => {
                draft.setHashToken(hash);
                draft.getSessionByHashToken({ setCookie });
                draft.setIsLogin(true);
                draft.reset();
              }),
            );

            if ((!redirectUrl || !redirectUrl?.length || redirectUrl === '') && (!inviteToken || inviteToken === 'undefined')) {
              setTimeout(() => {
                navigate(`${ROUTER_PATH.AuthRoutes.ACCOUNT_SETTINGS_INDEX}`);
              }, 1000);
            }

            if ((!redirectUrl || !redirectUrl?.length || redirectUrl === '') && !!inviteToken && inviteToken !== 'undefined') {
              setTimeout(() => {
                navigate(`${ROUTER_PATH.AuthRoutes.ACCOUNT_SETTINGS_INDEX}?inviteToken=${inviteToken}&redirect_url=${redirectUrl}`);
              }, 1000);
            }
          }
        } catch (e: any) {
          console.log('login error', e?.response?.data?.data?.error);
          set(
            produce((draft) => {
              draft.setLoginError(e?.response?.data?.data?.error);
            }),
          );
          return Promise.reject(e.response.data);
        }
      },
      setTotp: (value: string) => {
        set(
          produce((draft) => {
            draft.totp = value;
          }), false, {
            type: 'useLoginState => setTotp',
          },
        );
      },
      setStep: (value: number) => {
        set(
          produce((draft) => {
            draft.step = value;
          }), false, {
            type: 'useLoginState => setStep',
          },
        );
      },
      setHashToken: (value: string) => {
        set(
          produce((draft) => {
            draft.token = value;
          }), false, {
            type: 'useLoginState => setHashToken',
          },
        );
      },
      getSessionByHashToken: async ({ setCookie }: TGetSessionByHashToken) => {
        try {
          const token = useLoginState.getState().token;
          const res = await axios.get(`${EDENEX_ACCOUNT_URL}/edenex-account/api/get-token?token=${token}`, {
            headers: {
              'Authorization': ``,
            },
          });

          const { success, data } = res?.data;
          if (success) {
            const RefreshToken = JSON.stringify(data?.refresh_token);
            const AccessToken = JSON.stringify(data?.access_token);
            const Data = JSON.stringify(data);

            setCookie('refresh_token', RefreshToken);
            setCookie('token', AccessToken);
            setCookie('oidc.user', Data);

            useAuthState.getState().setIsAuth(true);
            fb.emit('loginEvent', true);
          }
        } catch (e) {
          console.log('getSessionByHashToken error', e);
        }
      },
      setEmail: (value: string) => {
        set(
          produce((draft) => {
            draft.email = value;
          }), false, {
            type: 'useLoginState => setEmail',
          },
        );
      },
      setPassword: (value: string) => {
        set(
          produce((draft) => {
            draft.password = value;
          }), false, {
            type: 'useLoginState => setPassword',
          },
        );
      },
      reset: () => {
        set(
          produce((draft) => {
            draft.password = '';
            draft.email = '';
            draft.totp = '';
          }), false, {
            type: 'useLoginState => reset',
          },
        );
      },
    }), {
      anonymousActionType: 'useLoginState action',
      name: 'useLoginState',
    },
  ),
);
