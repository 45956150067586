import styled, { keyframes } from 'styled-components';
import { ReactNode } from 'react';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const FadeWrapperStyled = styled.div<{ isVisible: boolean }>`
  animation: ${({ isVisible }) => (isVisible ? fadeIn : fadeOut)} 0.1s ease-in-out;
`;

type TFadeWrapper = {
  children: ReactNode,
  isVisible: boolean
}

const FadeWrapper = ({ children, isVisible }: TFadeWrapper) => {
  return <FadeWrapperStyled isVisible={isVisible}>
    {children}
  </FadeWrapperStyled>;
};

export default FadeWrapper;

