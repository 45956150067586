import { RouteObject } from 'react-router-dom';

const VERIFICATION_ROUTES: RouteObject[] = [
  // {
  //   path: ROUTER_PATH.AuthRoutes.VERIFICATION_INDEX,
  //   element: <VerificationIndexPage />,
  // },
];

export { VERIFICATION_ROUTES };
