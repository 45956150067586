import React, { FC, PropsWithChildren, useEffect } from 'react';
import { Modal } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { IconSelectedLanguage } from '../icons';

interface ILanguageModalProps {
  title: string;
  open: boolean;
  onOk?: () => void;
  onCancel: () => void;

}

type LanguageModalProps = PropsWithChildren<ILanguageModalProps>;

const LanguageModal: FC<LanguageModalProps> = ({ title, open, onCancel, onOk, }) => {
  const { i18n } = useTranslation();
  const languagesData = [
    // {
    //   text: "Español",
    //   key: 'es',
    //   status: 'es' === i18n.language
    // },
    // {
    //   text: "िंदी",
    //   key: 'hi',
    //   status: 'hi' === i18n.language
    // },
    // {
    //   text: "中國人",
    //   key: 'ch',
    //   status: 'ch' === i18n.language
    // },
    // {
    //   text: "Português",
    //   key: 'pt',
    //   status: 'pt' === i18n.language
    // },
    // {
    //   text: "日本語",
    //   key: 'jp',
    //   status: 'jp' === i18n.language
    // },
    // {
    //   text: "Français",
    //   key: 'fr',
    //   status: 'fr' === i18n.language
    // },
    // {
    //   text: "عرب",
    //   key: 'ar',
    //   status: 'ar' === i18n.language
    // },
    {
      text: "English",
      key: 'en',
      status: 'en' === i18n.language
    },
    {
      text: "Русский",
      key: 'ru',
      status: 'ru' === i18n.language
    },
  ]

  const handleOk = (lang: string) => {
    i18n.changeLanguage(lang);
    onCancel();
  }
  return (
    <ModalStyled
      title={title}
      open={open}
      onCancel={onCancel}
      onOk={onOk}
      footer={null}
    >
      {languagesData.map(el => (
        <ItemLanguageStyled
          onClick={() => handleOk(el.key)}
          key={el.key}>
          <div>{el.key}</div>
          <div>{el.text}</div>
          {el.status && <div><IconSelectedLanguage/></div>}
      </ItemLanguageStyled>
      ))}
    </ModalStyled>
  )
};

const ModalStyled = styled(Modal)`
  & .ant-modal-content{
    height: 100vh;
    border-radius: 0;
    background: #14141F !important;
  }
  top: 0;
  padding-bottom: 0;
`

const ItemLanguageStyled = styled.div`
  display: grid;
  grid-template-columns: 90px 1fr 60px;
  color: #fff;
  padding: 15px 0;
  border-bottom: 1px solid rgb(41, 41, 51);
`

export { LanguageModal };
